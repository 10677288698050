import {
	init,
	type GenericAnalyticWebClientPromise,
} from '@atlassian/react-ufo/interaction-metrics-init';
import { type Config } from '@atlassian/react-ufo/config';
import { configure as configureSsrTiming } from '@atlassian/react-ufo/ssr';

import { fg } from '@confluence/feature-gating';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getMark } from '@confluence/performance';
import type { SessionDataType } from '@confluence/session-data';

import { additionalPayloadData } from './additionalPayloadData';
import { getSSRDoneTime, getSSRTimings } from './ssr';

const getConfig = (sessionData: SessionDataType): Partial<Config> | undefined => {
	const config = sessionData.featureFlagClient.getRawValue('confluence.frontend.ufo.config', {
		default: '{}',
	});
	try {
		return JSON.parse(String(config));
	} catch (e) {}
};

export function initUFO(sessionData: SessionDataType) {
	const config = getConfig(sessionData);
	if (!config || !config?.enabled) {
		return;
	}

	configureSsrTiming({
		getDoneMark: () => getMark('CFP-63.ssr-ttr') ?? null, // from confluence/next/packages/browser-metrics/src/initializeBrowserMetrics.ts
		// TODO: add correct functions to `getTimings` and `getFeatureFlags`
		getTimings: () => null,
		getFeatureFlags: () => null,
	});

	const devToolsEnabled = sessionData.featureFlagClient.getBooleanValue(
		'confluence.frontend.enable.ttvc.devtool',
		{ default: false },
	);

	const stopVCAtInteractionFinish = fg('stop_vc_at_interaction_finish');

	init(getAnalyticsWebClient() as Promise<GenericAnalyticWebClientPromise>, {
		product: 'confluence',
		region: sessionData.shard || 'unknown',
		vc: {
			enabled: true,
			heatmapSize: 200,
			oldDomUpdates: false,
			devToolsEnabled,
			selectorConfig: {
				id: false,
				role: false,
				className: true,
				testId: false,
			},
			stopVCAtInteractionFinish,
		},
		...config,
		ssr: { getSSRDoneTime, getSSRTimings },
		additionalPayloadData: (interaction) =>
			additionalPayloadData(interaction, {
				cloudId: sessionData.cloudId,
				isShardPreProd: sessionData.shard
					? sessionData.shard.startsWith('conf-prod-us-preprod')
					: false,
			}),
	});
}
