import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { SPACE_ANALYTICS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import { SpaceAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { SPACE_ANALYTICS_PLUGIN, FEDRAMP_SPACE_ANALYTICS_PLUGIN } from '@confluence/named-routes';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager';

import SpaceBase from '../../SpaceBase';

export const SpaceAnalyticsRoute = ({ params: { spaceKey }, search }: RouteMatch) => {
	//TODO - CCIR-11: Clean up this feature gate once the migration is complete
	const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
		'confluence_frontend_analytics_ui_migration',
	);
	if (!isAnalyticsUiMigrationEnabled) {
		const isFedrampEnv = isFedRamp();

		const url = isFedrampEnv
			? FEDRAMP_SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey })
			: SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey });
		return <Redirection href={`${url}${search}`} />;
	}

	return (
		<SpaceBase>
			<SpaceAnalyticsPageLoader spaceKey={spaceKey} />
		</SpaceBase>
	);
};

SpaceAnalyticsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: { name: SPACE_ANALYTICS_EVENTS_SOURCE, id: spaceKey },
		pageState: {
			routeName: name,
			spaceKey,
		},
	},
	MainLayout: {
		spaceKey,
		navView: CONTAINER_HOME_ID,
	},
});
