import { useBooleanFeatureFlag } from '@confluence/session-data';

// featureFlags should come from getSessionData in @confluence/session-data
export function getMediaFeatureFlags(featureFlags: { [key: string]: any }) {
	return featureFlags
		? {
				folderUploads: Boolean(featureFlags['confluence.frontend.media.picker.folder.uploads']),
				captions: Boolean(featureFlags['confluence.frontend.fabric.editor.media.captions']),
				mediaInline: Boolean(featureFlags['confluence.frontend.fabric.editor.media.inline']),
				commentsOnMedia: Boolean(featureFlags['confluence.frontend.renderer.comments-on-media']),
				commentsOnMediaInsertExcerpt: Boolean(
					featureFlags['confluence.frontend.comments-on-media.bug.insert.excerpt'],
				),
			}
		: {};
}

export const useGetMediaFeatureFlags = () => {
	return {
		folderUploads: useBooleanFeatureFlag('confluence.frontend.media.picker.folder.uploads'),
		captions: useBooleanFeatureFlag('confluence.frontend.fabric.editor.media.captions'),
		commentsOnMedia: useBooleanFeatureFlag('confluence.frontend.renderer.comments-on-media'),
		commentsOnMediaInsertExcerpt: useBooleanFeatureFlag(
			'confluence.frontend.comments-on-media.bug.insert.excerpt',
		),
	};
};
