import React from 'react';

import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import type { IntentSchemaId } from '../../../provider/prompt-requests/types';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { Icon } from '../../assets/icons/findActionItems';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
} from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const findActionItemsConfigItem = createEditorPluginAIConfigItemMarkdown({
	key: CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	title: messages.title,
	description: messages.description,
	selectionType: SELECTION_TYPE,
	getInitialContext: ({ editorView, intl, updateIdMap, mentionMap }) => {
		const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
			node: editorView.state.doc,
			editorView,
			convertTo: 'markdown-plus',
			updateIdMap,
			selectionType: SELECTION_TYPE,
			mentionMap,
		});
		return {
			document: markdown,
			userLocale: intl.locale,
			intentSchemaId: 'action_items_intent_schema.json',
			contentStatistics,
		};
	},
	icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
	triggerPromptRequest({
		initialContext,
		editorSchema,
	}: {
		initialContext: {
			document: string;
			userLocale: string;
			intentSchemaId: IntentSchemaId;
		};
		editorSchema: Schema;
	}) {
		if (fg('platform_editor_ai_assistance_service')) {
			return streamConvoAI({
				userLocale: initialContext.userLocale,
				intentSchemaId: 'action_items_intent_schema.json',
				editorSchema,
				fullDocument: initialContext.document,
			});
		}
		return streamXPGenAI({
			userLocale: initialContext.userLocale,
			editorSchema,
			intentSchemaId: 'action_items_intent_schema.json',
			contextList: [
				{
					type: 'ADF_MARKDOWN_V1',
					entity: initialContext.document,
					relationship: 'FULL_CONTENT',
				},
			],
		});
	},
	isVisible: ({ editorView }) => isMinLength({ editorView, minLength: 1 }),
	actions: [
		createInsertAtTop({ appearance: 'secondary' }),
		createInsertAtCurrentPosition({ appearance: 'primary' }),
	],
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
});
