import uuid from 'uuid/v4';

import type FeatureFlagClient from '@atlaskit/feature-flag-client';

import { getLogger } from '@confluence/logger';
import { containsSessionExpiredError, containsNoNetworkError } from '@confluence/network';
import { getSessionData } from '@confluence/session-data';

import type { ExperienceState } from './Experience';
import { Experience } from './Experience';
import type { ExperienceAttributes, ExperienceEvent } from './ExperienceEvent';
import { getFreezeTracker } from './FreezeTracker';
import { hasHealthyRunningEditPageExperience } from './createAndEditPageExperience';
import { hasHealthyRunningViewPageExperience } from './viewPageSessionExperience';
import {
	EDIT_PAGE_EXPERIENCE,
	LIVE_PAGE_EDIT_EXPERIENCE,
	LIVE_PAGE_SESSION_EXPERIENCE,
	VIEW_PAGE_SESSION_EXPERIENCE,
} from './ExperienceName';

export type ExperienceEventSubscriber = (event: ExperienceEvent) => void;
export type ExperienceEventListenerUnsubscribe = () => void;
export type CollectFn = (events: ExperienceEvent[], experience: Experience) => void;

export type StartProps = {
	name: string;
	id?: string;
	timeout?: number;
	startTime?: number;
	attributes?: ExperienceAttributes;
	onSuccess?: () => void;
	onFailure?: () => void;
	onAbort?: () => void;
	collect?: CollectFn;
};

export type SucceedOptions = {
	name: string;
	attributes?: ExperienceAttributes;
};

export type FailOptions = {
	name?: string;
	error: Error;
	attributes?: ExperienceAttributes;
};

export type AbortOptions = {
	name?: string;
	reason: string;
	attributes?: ExperienceAttributes;
	checkForTimeout?: boolean;
};

export type StopOnErrorOptions = {
	name: string;
	error: Error;
	attributes?: ExperienceAttributes;
};

export interface ExperienceTrackerAPI {
	start(options: StartProps): void;
	succeed(options: SucceedOptions): void;
	fail(options: FailOptions): void;
	abort(options: AbortOptions): void;

	/**
	 * Fails or aborts an experience with a specific `name` because of a specific
	 * `error` based on product-specific logic. For example, if the specified
	 * `error` describes a network error or a user error (these are internal
	 * implementation details so do not rely that any particular classification of
	 * errors is really implemented), `ExperienceStop` may abort the experience with
	 * the specified `name` rather than fail it because the errors in question are
	 * not product failures.
	 */
	stopOnError(options: StopOnErrorOptions): void;

	subscribe(subscriber: ExperienceEventSubscriber): ExperienceEventListenerUnsubscribe;

	getExperienceState(experienceName: string): ExperienceState | null;
}

function getExperienceStopReason(error: Error): string | undefined {
	// message text check is a stop-gap measure due to re-packaging of errors. refactoring is underway.
	if (containsSessionExpiredError(error)) {
		return `Aborted because of session expiration error: ${error.toString()}`;
	}

	if (containsNoNetworkError(error)) {
		return `Aborted because of network error: ${error.toString()}`;
	}

	return undefined;
}

export class ExperienceTracker implements ExperienceTrackerAPI {
	private experiences: { [name: string]: Experience } = {};
	private subscribers: ExperienceEventSubscriber[] = [];
	private logger = getLogger('ExperienceTracker');
	private featureFlagClient: FeatureFlagClient | undefined = undefined;

	constructor() {
		getFreezeTracker().start();
		if (typeof window !== 'undefined') {
			window.addEventListener('pagehide', () => {
				const isLeaveEditPageExperienceSucceedEnabled = this.featureFlagClient?.getBooleanValue?.(
					'confluence.frontend.leave-edit-page-experience-succeed',
					{
						default: false,
					},
				);

				// Succeed the edit page experience if it's running and edit page subexperiences aren't unhealthy.
				if (isLeaveEditPageExperienceSucceedEnabled && hasHealthyRunningEditPageExperience()) {
					this.succeed({ name: EDIT_PAGE_EXPERIENCE });
				}
				// Succeed the view-page-session experience if it's running and view page subexperiences aren't unhealthy.
				if (hasHealthyRunningViewPageExperience()) {
					this.succeed({ name: VIEW_PAGE_SESSION_EXPERIENCE });
				}

				const livePageExperiencesToSucceed = [
					LIVE_PAGE_EDIT_EXPERIENCE,
					LIVE_PAGE_SESSION_EXPERIENCE,
				];
				livePageExperiencesToSucceed.forEach((experience) => {
					const experienceState = this.getExperienceState(experience);
					if (experienceState && !experienceState?.hasStopped) {
						this.succeed({ name: experience });
					}
				});

				// Abort the rest of our experiences.
				this.abort({ reason: 'Window unloading' });
			});
		}

		// When ExperienceTracker is first constructed, the featureFlagClient doesn't have up-to-date FF values, so we store the client and invoke it later when needed.
		void getSessionData().then(({ featureFlagClient }) => {
			this.featureFlagClient = featureFlagClient;
		});
	}

	start({
		name,
		id = uuid(),
		timeout,
		startTime,
		attributes,
		onSuccess,
		onFailure,
		onAbort,
		collect,
	}: StartProps) {
		const current = this.experiences[name];

		// Do not restart an experience that has the same id and is still running
		if (current && current.id === id && !current.hasStopped) return;

		// Before replacing a current experience, abort it to ensure it is cleaned up.
		if (current && !current.hasStopped) {
			current.abort({
				reason: 'Aborted because the same experience was started with a new id',
			});
		}

		const experience = new Experience({
			name,
			id,
			timeout,
			startTime,
			attributes,
			onSuccess,
			onFailure,
			onAbort,
			onStart: (startEvent) => {
				this.emit(startEvent);

				let disposeCollectSubscription = () => {};
				if (collect) {
					const events: ExperienceEvent[] = [];
					disposeCollectSubscription = this.subscribe((event) => {
						// Ignore already running experiences that started before this
						// experience started
						if (
							event.action === 'taskStart' ||
							events.some(
								(previousEvent) =>
									previousEvent.name === event.name && previousEvent.action === 'taskStart',
							)
						) {
							events.push(event);
							collect(events, experience);
						}
					});
				}

				return (stopEvent) => {
					disposeCollectSubscription();
					this.emit(stopEvent);
				};
			},
		});

		this.experiences[name] = experience;
	}

	succeed({ name, attributes }: SucceedOptions) {
		const current = this.experiences[name];
		if (!current) return;

		current.succeed(attributes);
	}

	fail({ name, error, attributes }: FailOptions) {
		Object.values(this.experiences).forEach((current) => {
			if (name != null && current.name !== name) return;

			current.fail({ error, attributes });
		});
	}

	abort({ name, reason, attributes, checkForTimeout }: AbortOptions) {
		Object.values(this.experiences).forEach((experience) => {
			if (name != null && experience.name !== name) return;

			experience.abort({ reason, checkForTimeout, attributes });
		});
	}

	stopOnError({ error, name, attributes }: StopOnErrorOptions): void {
		const reason = getExperienceStopReason(error);

		if (reason) {
			this.abort({
				name,
				reason,
				checkForTimeout: false,
				attributes,
			});
			return;
		}

		this.fail({ name, error, attributes });
	}

	subscribe(subscriber: ExperienceEventSubscriber): () => void {
		if (typeof subscriber !== 'function') {
			throw new Error('Subscriber must be a function');
		}
		this.subscribers.push(subscriber);
		return () => {
			this.subscribers = this.subscribers.filter((x) => x !== subscriber);
		};
	}

	getExperienceState(experienceName: string): ExperienceState | null {
		return this.experiences[experienceName] ? this.experiences[experienceName].getState() : null;
	}

	private emit(event: ExperienceEvent) {
		this.logger.debug`${event.action} ${event}`;
		this.subscribers.forEach((subscriber) => {
			try {
				subscriber(event);
			} catch (e) {
				this.logger
					.error`Error occurred in ExperienceTracker subscriber ${e} when handling event ${event}`;
			}
		});
	}
}
