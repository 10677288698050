import FeatureGates from '@atlaskit/feature-gate-js-client';

import { query } from '@confluence/query-preloader-tools';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';

import { SpaceNavigationQuery } from './SpaceNavigation/SpaceNavigationQuery.graphql';

export function preloadSideNavigation(spaceKey: string, isLicensed: boolean) {
	const tasks: Array<Promise<any>> = [
		query({
			query: SpaceNavigationQuery,
			variables: {
				spaceKey,
				isLicensed,
			},
		}),
	];

	const isBlogTreeUnderContentTreeEnabled = FeatureGates.checkGate('blog_tree_under_content_tree');

	if (isBlogTreeUnderContentTreeEnabled) {
		tasks.push(preloadBlogTree({ spaceKey }));
	}

	return Promise.all(tasks);
}
