import { query, writeQuery } from '@confluence/query-preloader-tools';
import { isExpectedApolloError } from '@confluence/error-types/entry-points/expectedGraphQlErrorHelper';

import { INITIAL_PAGE_SIZE } from './paginationLimits';
import type { GraphQLPageStatus } from './pageTreeStatuses';
import { PageTreeQuery, PageTreeWithSpaceKeyQuery } from './queries/PageTreeQuery.graphql';
import type {
	PageTreeWithSpaceKeyQuery as PageTreeWithSpaceKeyQueryType,
	PageTreeWithSpaceKeyQueryVariables,
} from './queries/__types__/PageTreeWithSpaceKeyQuery';
import type {
	PageTreeQuery as PageTreeQueryType,
	PageTreeQueryVariables,
} from './queries/__types__/PageTreeQuery';

export const PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME = 'PageTreeQuery';

const handleErrors = (result: any) => {
	// NOTES:
	// 1. Preloading is "fire and forget" operation on a client as well, so we can mute
	//    some known errors, but we can't really do anything about them as they're not
	//    make it to the Apollo cache anyway and therefore don't pop at where they can
	//    be handled.
	// 2. We use implicit dependency on GraphqlErrorProcessor from "@confluence/graphql"
	//    to avoid direct import of this package into preloader bundle.
	if (result?.errors?.length > 0) {
		result?.errors?.forEach((error: any) => {
			// The "Cannot find space" condition is handled by redirecting to the correct space
			// even if error is not propagated to ContentPrerequisites where it is normally handled.
			if (isExpectedApolloError(error)) {
				// space restrictions could also be a handled error, but that requires importing the
				// restrictions package which is too heavy for a preloader
				window?.__GRAPHQL_ERROR_PROCESSOR__?.markErrorAsHandled(error);
			}
		});
	}

	return result;
};

export type preloadPageTreeProps = {
	spaceKey: string;
	statuses: GraphQLPageStatus[];
	isSuperAdmin: boolean;
	contentId?: number | string;
};

export async function preloadPageTree({
	spaceKey,
	statuses,
	isSuperAdmin,
	contentId = '',
}: preloadPageTreeProps) {
	// only fetch using PageTreeWithSpaceKeyQuery if contentId does not exist AND FF is enabled (only for SSR space overviews).
	// This is to differentiate between other use cases where contentId is not passed (according to prop types, it is an optional param)
	if (spaceKey && !contentId) {
		const { data } = handleErrors(
			await query<PageTreeWithSpaceKeyQueryType, PageTreeWithSpaceKeyQueryVariables>({
				query: PageTreeWithSpaceKeyQuery,
				variables: {
					spaceKey,
					isSuperAdmin,
					statuses,
					// @ts-ignore
					ptStatuses: statuses,
					paginationLimit: INITIAL_PAGE_SIZE,
				},
			}),
		);
		if (data?.space?.homepageId && data?.space?.id) {
			writeQuery<PageTreeQueryType, PageTreeQueryVariables>({
				query: PageTreeQuery,
				variables: {
					spaceKey,
					isSuperAdmin,
					statuses,
					// @ts-ignore
					ptStatuses: statuses,
					contentId: data?.space?.homepageId,
					paginationLimit: INITIAL_PAGE_SIZE,
				},
				data: {
					currentPage: data?.space?.pageTree || null,
					rootLevelPages: data?.rootLevelPages || null,
					space: data?.space
						? {
								id: data?.space?.id,
								homepage: {
									id: data?.space?.homepageId,
									// @ts-ignore
									__typename: 'Content',
								},
								operations: data?.space?.operations,
								// @ts-ignore
								__typename: 'Space',
							}
						: null,
				},
			});
		}
	} else {
		handleErrors(
			await query({
				query: PageTreeQuery,
				variables: {
					spaceKey,
					isSuperAdmin,
					statuses,
					ptStatuses: statuses,
					...(contentId && { contentId }),
					paginationLimit: INITIAL_PAGE_SIZE,
				},
			}),
		);
	}
}
