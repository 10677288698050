import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';

import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import type ApolloClient from 'apollo-client';
import {
	EnvironmentContext,
	type Extension,
	makeFrame,
	ForgeUIRenderer as Renderer,
} from '@atlassian/forge-ui/ui';
import { ErrorWithCustomContent } from '@atlassian/forge-ui';
import { emit, on } from '@atlassian/forge-ui/events';
import {
	type ProductEnvironment,
	type CoreData,
	type ExtensionData,
	type ForgeDoc,
	type FrameProps,
} from '@atlassian/forge-ui-types';

import { type FlagFunctions, getFlagProvider } from '../../utils/getFlagProvider';
import { areCommonPropertiesEqual } from '../../utils/areCommonPropertiesEqual';
import { IframeRenderer } from './IframeRenderer';

interface ForgeUIExtensionProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	extensionData: ExtensionData;
	extension: Extension;
	environment: ProductEnvironment;
	locale: string;
	flags: FlagFunctions;
	isInline?: boolean;
}

type ViewportSizeType = 'small' | 'medium' | 'large' | 'xlarge';
type ViewportSizeTypeWithDefault = ViewportSizeType | 'default';
type ViewportSizeObjectType = {
	[size in ViewportSizeTypeWithDefault]: string;
};

const macroHeights: ViewportSizeObjectType = {
	small: '112px',
	medium: '262px',
	default: '262px',
	large: '524px',
	xlarge: '1048px',
};

const calculateHeight = (size?: ViewportSizeType) => {
	return macroHeights[size ?? 'default'];
};

export const ForgeUIExtension = ({
	accountId,
	apolloClient,
	coreData,
	extensionData,
	environment,
	extension,
	locale,
	flags,
	isInline = false,
}: ForgeUIExtensionProps) => {
	const { cloudId, localId } = coreData;

	const [macroConfigForgeDocSubject$] = useState(() => new Subject());
	const configValueRef = useRef(undefined);
	// Stores the forgeDoc for the macro config schema. The ref is often a render behind
	// the actual schema, so should only be used on the initial load.
	const macroConfigForgeDocRef = useRef<ForgeDoc | undefined>(undefined);

	// This sets up an event listener to respond to Editor's requests for the macro config schema.
	// When the GET_CONFIG_FORGE_DOC_${id} event is emitted, the payload includes the current
	// config value, and the resolve and reject functions of a Promise. The listener should be
	// resolving the promise with the value of the macro config forgeDoc.
	const id = `${extension.id}-${coreData.localId}`;
	const reconcile = useCallback(
		({ forgeDoc }: { forgeDoc: ForgeDoc }) => {
			Object.freeze(forgeDoc);
			macroConfigForgeDocRef.current = forgeDoc;
			macroConfigForgeDocSubject$.next(forgeDoc);
			emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
		},
		[id, macroConfigForgeDocSubject$],
	);

	useEffect(() => {
		const macroConfigForgeDocSubscription = on(
			`GET_CONFIG_FORGE_DOC_${id}`,
			async ({ resolve, reject, config }) => {
				// If the config value received in the event is different from the existing
				// stored value in configValueRef, then the forgeDoc will be updated.
				// We need to set a debounce time to wait for the new forgeDoc to be returned
				// from the app before we resolve the promise.
				if (configValueRef.current && !areCommonPropertiesEqual(configValueRef.current, config)) {
					const subscription = macroConfigForgeDocSubject$.pipe(debounceTime(200)).subscribe({
						next: (forgeDoc) => {
							resolve({
								type: 'Root',
								props: {},
								children: [forgeDoc],
							});
							subscription.unsubscribe();
						},
					});
				} else {
					if (!macroConfigForgeDocRef.current) {
						reject('MacroConfig forgeDoc is not defined.');
					}
					resolve({
						type: 'Root',
						props: {},
						children: [macroConfigForgeDocRef.current],
					});
				}
				configValueRef.current = config;
			},
		);

		return () => {
			macroConfigForgeDocSubscription.unsubscribe();
		};
	}, [id, macroConfigForgeDocSubject$]);

	const components = useMemo(
		() => ({
			Frame: makeFrame((inputProps: FrameProps) => {
				// Frame is not supported in inline layout for performance reasons
				if (isInline) {
					throw new ErrorWithCustomContent({
						logMessage: 'Frame rendered in macro inline layout',
						errorTitle: 'Error rendering component',
						errorBody: (
							<div>
								The Frame component does not support the inline layout for the macro module. Please
								use another component from the UI Kit{' '}
								<a
									href="https://developer.atlassian.com/platform/forge/ui-kit/components/"
									target="_blank"
								>
									components list.
								</a>
							</div>
						),
					});
				}
				return (
					<IframeRenderer
						accountId={accountId}
						apolloClient={apolloClient}
						contextIds={[`ari:cloud:confluence::site/${cloudId}`]}
						environment={environment}
						extension={{
							...extension,
							properties: {
								...extension.properties,
								...inputProps,
							},
						}}
						coreData={coreData}
						extensionData={extensionData}
						locale={locale}
						flags={flags}
					/>
				);
			}),
		}),
		[
			apolloClient,
			accountId,
			cloudId,
			isInline,
			environment,
			extension,
			coreData,
			extensionData,
			locale,
			flags,
		],
	);

	const { showFlag, closeFlag } = useMemo(() => getFlagProvider(flags), [flags]);

	return (
		<EnvironmentContext.Provider value={environment}>
			<Renderer
				accountId={accountId}
				bridge={{
					showFlag,
					closeFlag,
					reconcile,
				}}
				client={apolloClient}
				cloudId={cloudId!}
				components={components}
				contextIds={[`ari:cloud:confluence::site/${cloudId}`]}
				extension={extension}
				extensionData={extensionData}
				height={
					extension.properties.viewportSize
						? calculateHeight(extension.properties.viewportSize)
						: undefined
				}
				locale={locale}
				localId={localId}
				product="confluence"
				onForgeDocUpdated={(forgeDoc: ForgeDoc) => {
					if (forgeDoc.type === 'MacroConfig') {
						macroConfigForgeDocRef.current = forgeDoc;
						macroConfigForgeDocSubject$.next(forgeDoc);
						emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
					}
				}}
			/>
		</EnvironmentContext.Provider>
	);
};
