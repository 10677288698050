/* eslint-disable check-file/filename-blocklist */
export const CORE_INVITES_INVITE_THIRD_PARTY_TEAMS =
	'confluence.frontend.growth.invite-3rd-party-teams';

export const CORE_INVITES_DISPLAY_INFI_SPOTLIGHT =
	'confluence.frontend.growth.display-infi-spotlight';

export enum CoreInvitesSource {
	UNKNOWN = 'unknown',
	MENTION = 'mention',
	PEOPLE_MENU = 'peopleMenu',
	QSV2_INVITE_TEAMMATE_TASK = 'quickstart-checklist.invite-teammate-v2',
	ONBOARDING_INVITE_FLAG = 'onboarding-invite-flag',
	COLLABORATOR_ONBOARDING_INVITE_PEOPLE = 'collaborator-onboarding-invite-people',
	PERSISTENT_INVITE_PAGETREE = 'pageTree',
	PERSISTENT_INVITE_HOMESCREEN = 'homeScreen',
	CONTEXTUAL_INVITE_NUDGE = 'onboarding-contextual-invite-nudge',
	SHARE_DIALOG = 'share-dialog',
	SHARE_AND_RESTRICT_DIALOG = 'share-and-restrict-dialog',
	EMPTY_DEFAULT_NOTIFICATION = 'onboarding-empty-default-notification',
	IBO_TAKEOVER_INVITE = 'onboarding-takeover-invite-people',
	EA_DROPDOWN = 'ea-dropdown',
}
