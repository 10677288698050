import type { IntlShape } from 'react-intl-next';

import fg from '@atlaskit/feature-gate-js-client';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { ContextualToolbar } from '@atlaskit/editor-common/extensions';

import type { LegacyMacroManifest, ConfluencePageContext } from './extensionTypes';
import { getAnchorMacroCopyLinkCTA } from './toolbars/anchor';

export const getContextualToolbars = (
	macro: LegacyMacroManifest,
	intl: IntlShape,
	pageContext: ConfluencePageContext,
	isLivePage: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): ContextualToolbar[] | undefined => {
	if (macro.macroName === 'anchor' && fg.checkGate('cc_page_experiences_anchor_macro_refresh')) {
		return getAnchorMacroCopyLinkCTA(intl, pageContext, isLivePage, createAnalyticsEvent);
	}
	return;
};
