import React from 'react';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Placement } from '@atlassian/post-office-placement';
import { ScreenSpaceFlagsPlacementComponent } from '@post-office-placement/screen-space-flags/placement';

export const ScreenSpaceFlagsPlacement = () => {
	return FeatureGates.checkGate('post-office-screen-space-overlay-confluence') ? (
		<Placement
			placementId="screen-space-flags"
			PlacementComponent={ScreenSpaceFlagsPlacementComponent}
		/>
	) : null;
};
