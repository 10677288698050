import { defineMessages } from 'react-intl-next';

import type { StringField } from '@atlaskit/editor-common/extensions';
import fg from '@atlaskit/feature-gate-js-client';

import { MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH } from '../../../extensionConstants';

import type { FieldTransformer } from './types';

const i18n = defineMessages({
	defaultParameterValueFieldLabel: {
		id: 'fabric-extension-lib.anchor.config-panel.name-field.label',
		description: 'Label to be shown for the text field for anchor name in the config panel',
		defaultMessage: 'Name',
	},
	defaultParameterValueFieldDescription: {
		id: 'fabric-extension-lib.anchor.config-panel.name-field.description',
		description: 'Description to be shown for the text field for anchor name in the config panel',
		defaultMessage: 'Give this anchor link a unique name for easy reference.',
	},
});

export const anchor: FieldTransformer<any> = {
	transformFields: async (fields, _, intl) => {
		return fields.map((field) => {
			if (field.name !== 'defaultParameterValue') {
				return field;
			}

			const defaultParamValueFieldOverrides = fg.checkGate(
				'cc_page_experiences_anchor_macro_refresh',
			)
				? {
						label: intl.formatMessage(i18n.defaultParameterValueFieldLabel),
						description: intl.formatMessage(i18n.defaultParameterValueFieldDescription),
					}
				: {};

			return {
				...field,
				type: 'string',
				isRequired: true,
				...defaultParamValueFieldOverrides,
			} as StringField;
		});
	},
	// Transforming name that user entered into a valid URI. Not a great experience, need to find a way to inform the user and allow them to pick again.
	transformAfter(params) {
		if (params.defaultParameterValue) {
			// Strip spaces off the beginning and end of the name
			params.defaultParameterValue = params.defaultParameterValue.replace(/^\s+/g, '');
			params.defaultParameterValue = params.defaultParameterValue.replace(/\s+$/g, '');

			// Changing spaces to '-' to match header logic
			params.defaultParameterValue = params.defaultParameterValue.replace(/\s+/g, '-');

			// Stripping out quotes for security (VULN-539679)
			params.defaultParameterValue = params.defaultParameterValue.replace(/["']+/g, '');

			params.defaultParameterValue = params.defaultParameterValue.substr(
				0,
				MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH,
			);
		}

		return params;
	},
};
