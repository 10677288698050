/** @jsx jsx */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { css, jsx } from '@emotion/react';
import { type CSSProperties } from 'react';
import { ModalBody } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import { type ModalSizesType } from '../../custom-ui';

type ModalWrapperProps = { children: JSX.Element; size: ModalSizesType; styles?: CSSProperties };

const smallModalStyles = css({
	height: '20vh',
	minHeight: '320px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const mediumModalStyles = css({
	height: '40vh',
	minHeight: '520px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const largeModalStyles = css({
	height: '70vh',
	minHeight: '720px',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const xlargeModalStyles = css({
	height: '90vh',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const maxModalStyles = css({
	height: '100%',
	background: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

// TODO: Fix below styles once we move to compiled/react
export const ModalWrapper = ({ children, size, styles }: ModalWrapperProps) => {
	switch (size) {
		case 'small':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[smallModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'medium':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[mediumModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'large':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[largeModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'xlarge':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[xlargeModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'max':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[maxModalStyles]} style={styles}>
					{children}
				</div>
			);
		default:
			return <ModalBody>{children}</ModalBody>;
	}
};

type ModalWidths = 'small' | 'medium' | 'large' | 'xlarge' | 'max';

export const getAtlasKitModalWidth = (modalSize: ModalWidths) => {
	if (modalSize === 'xlarge') {
		return 'x-large';
	}
	if (modalSize === 'max') {
		return '100%';
	}
	return modalSize;
};
