import { LoadablePaint } from '@confluence/loadable';

export const LazyConfigurationPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConfigurationContainer" */ './Configuration/ConfigurationPage'
			)
		).ConfigurationPage,
});

export const LazyDefaultSpaceLogoConfigPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DefaultSpaceLogoConfigContainer" */ './DefaultSpaceLogoConfig/DefaultSpaceLogoConfigPage'
			)
		).DefaultSpaceLogoConfigPage,
});

export const LazyHeaderAndFooterPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-HeaderAndFooterContainer" */ './HeaderAndFooter/HeaderAndFooterPage'
			)
		).HeaderAndFooterPage,
});

export const LazyTemplatesAndBlueprintsPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TemplatesAndBlueprintsContainer" */ './TemplatesAndBlueprints/TemplatesAndBlueprintsPage'
			)
		).TemplatesAndBlueprintsPage,
});

export const LazyCodeMacroConfigurationPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConfigureCodeMacroContainer" */ './ConfigureCodeMacro/ConfigureCodeMacroPage'
			)
		).ConfigureCodeMacroPage,
});

export const LazyColorSchemePage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ColorSchemeContainer" */ './ColorScheme/ColorSchemePage'
			)
		).ColorSchemePage,
});

export const LazySecurityConfigurationPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SecurityConfigurationContainer" */ './SecurityConfiguration/SecurityConfigurationPage'
			)
		).SecurityConfigurationPage,
});

export const LazyApplicationNavigatorPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ApplicationNavigatorContainer" */ './ApplicationNavigator/ApplicationNavigatorPage'
			)
		).ApplicationNavigatorPage,
});

export const LazyTrashedSpacesPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TrashedSpacesContainer" */ './TrashedSpaces/TrashedSpacesPage'
			)
		).TrashedSpacesPage,
});

export const LazyImportSpacesPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ImportSpacesContainer" */ './ImportSpaces/ImportSpacesPage'
			)
		).ImportSpacesPage,
});

export const LazyJiraMacroRepairPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-JiraMacroRepairContainer" */ './JiraMacroRepair/JiraMacroRepairPage'
			)
		).JiraMacroRepairPage,
});

export const LazyMacroUsagePage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-MacroUsageContainer" */ './MacroUsage/MacroUsagePage'
			)
		).MacroUsagePage,
});

export const LazyThemesPage = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ThemesContainer" */ './Themes/ThemesPage'))
			.ThemesPage,
});

export const LazyPdfExportPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PdfExportContainer" */ './PdfExport/PdfExportPage'
			)
		).PdfExportPage,
});

export { ADMIN_CONFIGURATION_ANALYTICS_SOURCE } from './Configuration/configurationConstants';
export { ADMIN_DEFAULT_SPACE_LOGO_CONFIG_ANALYTICS_SOURCE } from './DefaultSpaceLogoConfig/defaultSpaceLogoConfigConstants';
export { ADMIN_MIGRATION_HEADER_AND_FOOTER_ANALYTICS_SOURCE } from './HeaderAndFooter/headerAndFooterConstants';
export { ADMIN_MIGRATION_TEMPLATES_AND_BLUEPRINTS_ANALYTICS_SOURCE } from './TemplatesAndBlueprints/templatesAndBlueprintsConstants';
export { ADMIN_CONFIGURE_CODE_MACRO_ANALYTICS_SOURCE } from './ConfigureCodeMacro/configureCodeMacroConstants';
export { ADMIN_COLOR_SCHEME_ANALYTICS_SOURCE } from './ColorScheme/colorSchemeConstants';
export { ADMIN_SECURITY_CONFIGURATION_ANALYTICS_SOURCE } from './SecurityConfiguration/securityConfigurationConstants';
export { ADMIN_APPLICATION_NAVIGATOR_ANALYTICS_SOURCE } from './ApplicationNavigator/applicationNavigatorConstants';
export { ADMIN_TRASHED_SPACES_ANALYTICS_SOURCE } from './TrashedSpaces/trashedSpacesConstants';
export { ADMIN_IMPORT_SPACES_ANALYTICS_SOURCE } from './ImportSpaces/importSpacesConstants';
export { ADMIN_JIRA_MACRO_REPAIR_ANALYTICS_SOURCE } from './JiraMacroRepair/jiraRepairMacroConstants';
export { ADMIN_MACRO_USAGE_ANALYTICS_SOURCE } from './MacroUsage/macroUsageConstants';
export { ADMIN_THEMES_ANALYTICS_SOURCE } from './Themes/themesConstants';
export { ADMIN_PDF_EXPORT_ANALYTICS_SOURCE } from './PdfExport/pdfExportConstants';
export {
	type MaybeGraphQLErrors,
	type GraphQLErrorHandler,
	handleGraphQLErrors,
} from './common/handleGraphQLErrors';
