import { query } from '@confluence/query-preloader-tools';

import { BlogTree } from './BlogTreeQuery.graphql';
import { PAGE_SIZE, BLOG_STATUSES } from './treeParameters';

/*
 * preloadViewPageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadBlogTree = ({ spaceKey }) => {
	const tasks: Array<Promise<any>> = [
		query({
			query: BlogTree,
			variables: {
				spaceKey,
				first: PAGE_SIZE,
				statuses: BLOG_STATUSES,
			},
		}),
	];

	return Promise.all(tasks);
};
