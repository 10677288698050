import { getExperimentCohort } from '@atlassian/generative-ai-modal/utils/experiments';

import {
	DELAY_BETWEEN_TWO_CHECKS,
	DELAY_INCREASE,
	MAX_DELAY,
	NUMBER_OF_BLOCKS_PER_REQUEST,
	NUMBER_OF_REQUESTS_FOR_INCREASE,
	NUMBER_OF_SENTENCES_PER_REQUEST,
} from '../pm-plugins/ai-spelling-grammar/document-sg-checker';
import { type ProactiveAIConfig } from '../types';

// Note -- when updating this function -- also update
// the one used in packages/editor/editor-plugin-ai/examples/utils/getDevFetchCustomHeaders.ts
export function _getFetchCustomHeaders(): HeadersInit {
	const currentHost = window.location.origin;

	switch (true) {
		case currentHost === 'https://statlas.prod.atl-paas.net':
			// Update this to add custom headers when run in atlaskit branch deploys
			// ie.
			// {
			//   'X-CloudID': 'Magic string'
			// }
			return {};
		default:
			return {};
	}
}

export function deriveHost() {
	const {
		origin = '',
		port = '',
		hostname = '',
	} = typeof window.location !== 'undefined' ? window.location : {};

	if (
		(hostname === 'localhost' && port !== '8081') ||
		[
			'atlaskit-website-staging.stg-east.frontend.public.atl-paas.net',
			'statlas.prod.atl-paas.net',
			'atlaskit.atlassian.com',
		].some((h) => hostname === h)
	) {
		return 'https://pug.jira-dev.com';
	}

	return origin;
}

export function deriveGenerativeAIApiUrl() {
	return `${deriveHost()}/gateway/api/editor-ai/v1/generative/ai`;
}

/**
 * Tech Spec: https://hello.atlassian.net/wiki/spaces/CA3/pages/3776161653/Bulk+Spelling+and+Grammar+Endpoint
 */
export function deriveProactiveAIApiUrl() {
	const url = new URL(`/gateway/api/v1/grammar`, deriveHost());

	const variation = getExperimentCohort('editor_ai_-_proactive_ai_model_variations');
	if (variation !== 'control' && variation !== 'not-enrolled') {
		url.searchParams.set('variation', variation);
	}

	return url.toString();
}

const DEFAULT_CURRENT_CHUNKS_DEBOUNCED_TIME = 4000;
const DEFAULT_CURRENT_CHUNKS_DEBOUNCED_WAIT_TIME = 30000;
const DEFAULT_NON_CURRENT_CHUNKS_THROTTLED_TIME = 2000;

export function deriveProactiveAIConfig(overrides?: Partial<ProactiveAIConfig>) {
	return {
		enabled: false,
		apiUrl: deriveProactiveAIApiUrl(),
		defaultToggledState: false,
		timings: {
			currentChunks: DEFAULT_CURRENT_CHUNKS_DEBOUNCED_TIME,
			currentChunksMaxWait: DEFAULT_CURRENT_CHUNKS_DEBOUNCED_WAIT_TIME,
			nonCurrentChunks: DEFAULT_NON_CURRENT_CHUNKS_THROTTLED_TIME,
		},
		documentSGChecker: {
			enabled: true,
			blocksPerRequest: NUMBER_OF_BLOCKS_PER_REQUEST,
			sentencesPerRequest: NUMBER_OF_SENTENCES_PER_REQUEST,
			timings: {
				delayBetweenChecks: DELAY_BETWEEN_TWO_CHECKS,
				numberOfRequestsForIncrease: NUMBER_OF_REQUESTS_FOR_INCREASE,
				delayIncrease: DELAY_INCREASE,
				maxDelay: MAX_DELAY,
			},
		},
		...overrides,
	};
}

export const convoAIStatelessApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/invoke_agent/stream`;
export const convoAIStatefulApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/channel`; // + `/${channelID}/message/stream` at the end;
