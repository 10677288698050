import type { Schema as PMSchema } from '@atlaskit/editor-prosemirror/model';

import type { EditorPluginAIPromptRequestMarkdown } from '../../types';
import { startStreamingResponse } from '../start-streaming-response';

import type {
	ContextList,
	IntentSchemaId,
	IntentSchemaIdWithCustomPrompt,
	PromptRequestOptions,
	TranslateIntentSchema,
} from './types';
import { getSupportedMarkdowns } from './utils/get-supported-markdown-from-schema';
import { streamResponseParser } from './utils/stream-response-parser';

type CommonXPGenAIInput = {
	intentSchemaId: Exclude<IntentSchemaId, IntentSchemaIdWithCustomPrompt | TranslateIntentSchema>;
	contextList: ContextList;
	userLocale: string;
	editorSchema: PMSchema;
	streamingListener?: (markdown: string, status: string) => void;
};

type XPGenAIInputWithCustomPrompt = Omit<CommonXPGenAIInput, 'intentSchemaId'> & {
	intentSchemaId: IntentSchemaIdWithCustomPrompt;
	customPrompt: string;
	draftSelectedContentOverride?: string;
};

type XPGenAITranslateInput = Omit<CommonXPGenAIInput, 'intentSchemaId'> & {
	intentSchemaId: TranslateIntentSchema;
	targetTranslationLanguage: string;
};

type XPGenAIInput = CommonXPGenAIInput | XPGenAIInputWithCustomPrompt | XPGenAITranslateInput;

/**
 * Transforms some text
 *
 * Intended for use in EditorPluginAI config items.
 * @example
 * ```ts
 * triggerPromptRequest({ initialContext }) {
 *   return transform(initialContext.doc);
 * }
 * ```
 *
 * @see https://hello.atlassian.net/wiki/spaces/CA3/pages/2485466879/Generative+AI+API+Specs
 */
export const streamXPGenAI = (
	input: XPGenAIInput,
	customEndpoint?: string,
): EditorPluginAIPromptRequestMarkdown => {
	return function promptRequest(requestOptions: PromptRequestOptions) {
		const streamingResponseGenerator = startStreamingResponse({
			endpoint: customEndpoint || requestOptions.generativeAIApiUrl,
			payload: JSON.stringify({
				intentSchemaId: input.intentSchemaId,
				contextList: input.contextList,
				modelPlatform: 'OPENAI',
				outputLocale: input.userLocale,
				streaming: true,
				outputFormat: {
					outputFormatType: 'MARKDOWN',
					markdowns: getSupportedMarkdowns(input.editorSchema),
				},
				product: requestOptions.product,
				...('customPrompt' in input &&
					input.customPrompt && {
						userInstruction: input.customPrompt,
					}),
				...('targetTranslationLanguage' in input &&
					input.targetTranslationLanguage && {
						targetTranslationLanguage: input.targetTranslationLanguage,
					}),
			}),
			...requestOptions,
		});

		return streamResponseParser({
			streamingResponseGenerator,
			...('streamingListener' in input &&
				input.streamingListener && {
					streamingListener: input?.streamingListener,
				}),
		});
	};
};
