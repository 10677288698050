import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Box } from '@atlaskit/primitives';

import { INSTANCE_ANALYTICS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { Redirection } from '@confluence/route-manager';

export const InstanceAnalyticsRoute = ({ search }) => {
	//TODO - CCIR-11: Clean up this feature gate once the migration is complete
	const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
		'confluence_frontend_analytics_ui_migration',
	);
	if (!isAnalyticsUiMigrationEnabled) {
		const isFedrampEnv = isFedRamp();

		const url = isFedrampEnv
			? '/wiki/plugins/servlet/ac/com.addonengine.analytics.moderate/analytics-instance-page'
			: '/wiki/plugins/servlet/ac/com.addonengine.analytics/analytics-instance-page';
		return <Redirection href={`${url}${search}`} />;
	}

	return <Box padding="space.1000">Instance Analytics will go here</Box>;
};

InstanceAnalyticsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: INSTANCE_ANALYTICS_EVENTS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
