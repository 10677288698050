import memoizeOne from 'memoize-one';

import type { RendererAppearance } from '@atlaskit/renderer';

import type { ContentAppearanceToAtlaskitMap } from './types';
import { ContentAppearanceType } from './types';

const _contentAppearanceToAtlaskitMap: ContentAppearanceToAtlaskitMap = {
	[ContentAppearanceType.DEFAULT]: 'full-page',
	[ContentAppearanceType.FULL_WIDTH]: 'full-width',
};

export const getContentAppearanceForAtlaskit = memoizeOne(
	(contentAppearance: ContentAppearanceType): RendererAppearance => {
		const appearanceForAtlaskit = _contentAppearanceToAtlaskitMap[contentAppearance];
		if (appearanceForAtlaskit) {
			return appearanceForAtlaskit;
		}
		return getDefaultContentAppearanceForAtlaskit();
	},
);

export const getDefaultContentAppearanceForAtlaskit = (): RendererAppearance => {
	return _contentAppearanceToAtlaskitMap[ContentAppearanceType.DEFAULT];
};
