import omit from 'lodash/omit';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { objectHash } from '@confluence/object-hash';
import type { RouteMatch, MatchQuery } from '@confluence/route';
import { getSingleParam } from '@confluence/route-manager-utils';
import {
	EDIT_PAGE_V2,
	EDIT_BLOG_V2,
	EDIT_PAGE,
	EDIT_BLOG,
	VIEW_PAGE,
	VIEW_BLOG,
	VIEW_BLOG_DATE_LEGACY,
	SPACE_OVERVIEW,
	EDIT_TEMPLATE,
	EDIT_TEMPLATE_SPACE,
	CREATE_TEMPLATE,
	CREATE_TEMPLATE_SPACE,
	ADVANCED_SEARCH,
	HOME,
	SPACE_ARCHIVED_PAGES,
	SPACE_BULK_TOOLS,
	SPACE_DIRECTORY,
	SPACE_MANAGER,
	TEMPLATE_GALLERY,
	SPACE_PAGES,
	EDIT_PAGE_EMBED,
	CREATE_CONTENT,
	CREATE_PAGE,
	COMPANY_HUB,
	EXTERNAL_SHARE,
	WHITEBOARD_BOARD,
	SPACE_PERMISSIONS_SETTINGS,
	ADMIN_ANNOUNCEMENT_BANNER_RENDERER,
	ADMIN_NEW_GLOBAL_PERMISSIONS,
	DATABASE_VIEW,
	EMBED_VIEW,
	ADMIN_COMPANY_HOME_BUILDER,
	ADMIN_CUSTOMIZE_NAVIGATION,
} from '@confluence/named-routes';
import type { SessionDataType } from '@confluence/session-data';
import {
	getCompanyHubSpaceKey,
	isCompanyHubSpaceKey,
} from '@confluence/route-manager/entry-points/companyHubUtils';

import {
	RESTRICTED_PAGE_EXPERIENCE,
	RESTRICTED_SPACE_EXPERIENCE,
	RESTRICTED_SPACE_FOR_GUEST_EXPERIENCE,
	NOT_FOUND_EXPERIENCE,
	CONNECT_ADDON_EXPERIENCE,
	CREATE_PAGE_EXPERIENCE,
	CREATE_PAGE_LOAD_DIALOG_EXPERIENCE,
	CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE,
	EDIT_PAGE_EMBED_EXPERIENCE,
	EDIT_PAGE_EXPERIENCE,
	EDIT_LEGACY_PAGE_EXPERIENCE,
	EDIT_PAGE_CLOSE_EXPERIENCE,
	EDIT_LEGACY_PAGE_CLOSE_EXPERIENCE,
	EDIT_PAGE_LOAD_EXPERIENCE,
	EDIT_LEGACY_PAGE_LOAD_EXPERIENCE,
	EDIT_PAGE_PUBLISH_EXPERIENCE,
	EDIT_LEGACY_PAGE_PUBLISH_EXPERIENCE,
	VIEW_CONTENT_EXPERIENCE,
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	VIEW_PAGE_SESSION_EXPERIENCE,
	VIEW_PUBLIC_LINK_SITE_SETTINGS_EXPERIENCE,
	VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE,
	TEMPLATE_EDIT_PAGE_EXPERIENCE,
	TEMPLATE_EDIT_PAGE_CLOSE_EXPERIENCE,
	TEMPLATE_EDIT_PAGE_LOAD_EXPERIENCE,
	TEMPLATE_EDIT_PAGE_PUBLISH_EXPERIENCE,
	ADVANCED_SEARCH_EXPERIENCE,
	SPACE_ARCHIVED_PAGES_EXPERIENCE,
	MANAGE_PAGES_EXPERIENCE,
	SPACE_DIRECTORY_EXPERIENCE,
	TEMPLATE_GALLERY_CATEGORIES_EXPERIENCE,
	TEMPLATE_GALLERY_SPACE_SELECTOR_EXPERIENCE,
	TEMPLATE_GALLERY_CARDS_EXPERIENCE,
	TEMPLATE_GALLERY_TEMPLATE_DETAIL_EXPERIENCE,
	TEMPLATE_GALLERY_EXPERIENCE,
	SPACE_PAGES_EXPERIENCE,
	WHITEBOARD_EXPERIENCE,
	WHITEBOARD_CONTENT_EXPERIENCE,
	CONTENT_TYPES_HEADER_EXPERIENCE,
	CREATE_WHITEBOARD_EXPERIENCE,
	VIEW_PUBLIC_LINKS_SPACE_SETTINGS_EXPERIENCE,
	ADMIN_ANNOUNCEMENT_BANNER_SETTINGS_EXPERIENCE,
	SPACE_MANAGER_EXPERIENCE,
	DATABASE_EXPERIENCE,
	DATABASE_CONTENT_EXPERIENCE,
	CREATE_DATABASE_EXPERIENCE,
	EMBED_EXPERIENCE,
	EMBED_CONTENT_EXPERIENCE,
	CREATE_EMBED_EXPERIENCE,
	COMPANY_HOME_BUILDER_LOAD_EXPERIENCE,
	CONTENT_TYPES_HEADER_WATCH_EXPERIENCE,
	LIVE_PAGE_EDIT_EXPERIENCE,
	LIVE_PAGE_SESSION_EXPERIENCE,
} from './ExperienceName';
import type { ExperienceEvent } from './ExperienceEvent';
import { isStop, hasName } from './ExperienceEvent';
import { ExperienceTimeout } from './ExperienceTimeout';
import type { StartProps } from './ExperienceTracker';
import { getExperienceTracker } from './getExperienceTracker';
import { collectAll, collectAny } from './ExperienceEventCollector';
import {
	hasHealthyRunningEditPageExperience,
	startCreateAndEditPage,
} from './createAndEditPageExperience';
import { hasHealthyRunningViewPageExperience } from './viewPageSessionExperience';
import { startHome } from './homeExperience';
import type { Experience } from './Experience';

const stopOnNoPermissionOrNotFound = collectAny([
	NOT_FOUND_EXPERIENCE,
	RESTRICTED_PAGE_EXPERIENCE,
	RESTRICTED_SPACE_EXPERIENCE,
	RESTRICTED_SPACE_FOR_GUEST_EXPERIENCE,
]);

const stopOnConnectAddon = collectAny([CONNECT_ADDON_EXPERIENCE]);

/**
 * Stores the new experiences started due to SPA transition
 */
const namesStartedInPreviousRoute: string[] = [];

/**
 *  Specifies routes that signify completion for each experience.
 *  Used with experienceTracker's collect function to monitor progress of experiences.
 *  For example, 'create page' experience is considered complete when it leads to 'edit page'.
 */
const redirectRoutesForExperiences: { [key: string]: string[] } = {
	[CREATE_PAGE_EXPERIENCE]: [EDIT_PAGE_V2.name, EDIT_BLOG_V2.name, EDIT_PAGE.name, EDIT_BLOG.name],
	[CREATE_WHITEBOARD_EXPERIENCE]: [WHITEBOARD_BOARD.name],
	[CREATE_DATABASE_EXPERIENCE]: [DATABASE_VIEW.name],
	[CREATE_EMBED_EXPERIENCE]: [EMBED_VIEW.name],
};

export const routeNameToContentTypeMap = {
	[SPACE_OVERVIEW.name]: 'space',
	[COMPANY_HUB.name]: 'space',
	[EDIT_PAGE_EMBED.name]: 'page',
	[EDIT_PAGE_V2.name]: 'page',
	[EDIT_BLOG_V2.name]: 'blog',
	[VIEW_PAGE.name]: 'page',
	[VIEW_BLOG.name]: 'blog',
	[VIEW_BLOG_DATE_LEGACY.name]: 'blog',
};

export const routeNameToEditorType = (routeName: string) => {
	switch (routeName) {
		case EDIT_BLOG.name:
		case EDIT_PAGE.name:
		case CREATE_PAGE.name:
			return 'TINY';
		default:
			return 'FABRIC';
	}
};

export function startExperienceForRoute(
	match: RouteMatch,
	transitionId: number,
	sessionData?: SessionDataType,
) {
	if (!match) {
		return;
	}

	const tracker = getExperienceTracker();

	const isCreatePageFailureFixEnabled = sessionData?.featureFlagClient.getBooleanValue(
		'confluence.frontend.create-page-experience-fix',
		{
			default: false,
		},
	);

	const isLeaveEditPageExperienceSucceed = sessionData?.featureFlagClient.getBooleanValue(
		'confluence.frontend.leave-edit-page-experience-succeed',
		{
			default: false,
		},
	);

	// Live page's edit-page and session experience starts after the route has finished loading and unsubscribed from the tracker, so it's not in the `startExperienceForRoute` function's namesStartedInPreviousRoute array. We will handle finishing it separately in `finishLivePageExperiences`.
	finishLivePageExperiences();

	namesStartedInPreviousRoute.forEach((name) => {
		if (isCreatePageFailureFixEnabled) {
			const allowedRoutes = redirectRoutesForExperiences[name];

			// For create content routes, we do not want to abort if it is routing to correct page.
			// But, if it is a create experience and routing to a page is is not supposed to be going to,
			// we can assume something is wrong and we abort it.
			if (allowedRoutes && allowedRoutes.includes(match.name)) return;
		}

		if (
			// If we were on edit-page or view-page/session experience and leave to another route, we should succeed that experience, not abort it.
			(isLeaveEditPageExperienceSucceed &&
				name === EDIT_PAGE_EXPERIENCE &&
				hasHealthyRunningEditPageExperience()) ||
			(name === VIEW_PAGE_SESSION_EXPERIENCE && hasHealthyRunningViewPageExperience())
		) {
			tracker.succeed({
				name,
			});
			return;
		}

		// For all other situations, we still abort.
		tracker.abort({
			name,
			reason: 'Aborted due to SPA started a new experience',
		});
	});

	namesStartedInPreviousRoute.length = 0;
	const unsubscribe = tracker.subscribe((e) => {
		if (e.action === 'taskStart') {
			namesStartedInPreviousRoute.push(e.name);
		}
	});

	switch (match.name) {
		case EDIT_PAGE_EMBED.name:
		case EDIT_PAGE_V2.name:
		case EDIT_BLOG_V2.name:
		case EDIT_PAGE.name:
		case EDIT_BLOG.name:
		case CREATE_PAGE.name:
			startEditPage(match, transitionId, sessionData);
			break;
		case VIEW_PAGE.name:
		case VIEW_BLOG.name:
		case VIEW_BLOG_DATE_LEGACY.name:
			startViewContent(
				match.params.contentId,
				transitionId,
				routeNameToContentTypeMap[match.name],
				match.query,
				sessionData,
			);
			break;
		case ADMIN_NEW_GLOBAL_PERMISSIONS.name:
			startViewPublicLinkGlobalSettings(match.query.tab, match.query.space, transitionId);
			break;
		case EXTERNAL_SHARE.name:
			startViewPublicLinkSharedPage(match.params.id, transitionId);
			break;
		case SPACE_OVERVIEW.name:
			startViewContent(
				match.params.spaceKey,
				transitionId,
				routeNameToContentTypeMap[match.name],
				match.query,
				sessionData,
				{ spaceKey: match.params.spaceKey },
			);
			break;
		case COMPANY_HUB.name:
			const spaceKey = getCompanyHubSpaceKey(sessionData) || '';
			startViewContent(
				spaceKey,
				transitionId,
				routeNameToContentTypeMap[match.name],
				match.query,
				sessionData,
				{
					spaceKey,
				},
			);
			break;
		case HOME.name:
			startHome();
			break;
		case EDIT_TEMPLATE_SPACE.name:
		case EDIT_TEMPLATE.name:
			startTemplateEditPage(match.params.contentId, transitionId);
			break;
		case CREATE_TEMPLATE_SPACE.name:
		case CREATE_TEMPLATE.name:
			startTemplateEditPage(/* id */ undefined, transitionId);
			break;
		case ADVANCED_SEARCH.name:
			startAdvancedSearch(match);
			break;
		case SPACE_ARCHIVED_PAGES.name:
			startArchivedPagesList();
			break;
		case SPACE_BULK_TOOLS.name:
			startManagePages();
			break;
		case SPACE_DIRECTORY.name:
			startSpaceDirectory();
			break;
		case TEMPLATE_GALLERY.name:
			startTemplateGallery();
			break;
		case SPACE_PAGES.name:
			startSpacePages();
			break;
		case WHITEBOARD_BOARD.name:
			startWhiteboardBoard(match);
			break;
		case DATABASE_VIEW.name:
			startDatabaseView(match.params.contentId);
			break;
		case EMBED_VIEW.name:
			startEmbedPageView(match.params.contentId);
			break;
		case SPACE_PERMISSIONS_SETTINGS.name:
			//Adding the other space perms uplift tabs at a later date.
			if (match.params.tab === 'public-links') {
				startViewPublicLinksSpaceSettings();
			}
			break;
		case ADMIN_ANNOUNCEMENT_BANNER_RENDERER.name:
			startViewAdminAnnouncementBannerSettings();
			break;
		case SPACE_MANAGER.name:
			startSpaceManager();
			break;
		case ADMIN_COMPANY_HOME_BUILDER.name:
		case ADMIN_CUSTOMIZE_NAVIGATION.name:
			startCompanyHomeBuilderLoad();
			break;
	}

	const startCreateOperations = () => {
		if (match.name === CREATE_CONTENT.name) {
			startCreateContent(match, sessionData);
		} else if (match.query.createDialog) {
			startCreatePage(String(transitionId), transitionId);
			startCreateAndEditPage();
		}
	};

	if (!isCreatePageFailureFixEnabled) {
		unsubscribe();
		startCreateOperations();
	} else {
		/*
		This is an experiment to fix the create page failures.
		The strategy is to start tracking create page experiences across route changes and abort them if they are going to a route they are not supposed to.
		The intention is to simplify this code once the experiment findings are conclusive in this ticket: https://product-fabric.atlassian.net/browse/MODES-3751
		*/
		startCreateOperations();
		unsubscribe();
	}
}

function startCreatePage(id: string, transitionId: number) {
	const experienceTracker = getExperienceTracker();

	experienceTracker.start({
		name: CREATE_PAGE_EXPERIENCE,
		id,
		startTime: transitionId === 0 ? 0 : undefined,
		collect(events, experience) {
			experience.stopOn(
				events.find(
					(event) =>
						event.action === 'taskFail' &&
						hasName(
							event,
							CREATE_PAGE_LOAD_DIALOG_EXPERIENCE,
							CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE,
						),
				),
			);

			experience.stopOn(
				events.find(
					(event) =>
						event.action === 'taskSuccess' &&
						hasName(event, CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE),
				),
			);

			experience.stopOn(
				events.find(
					(event) =>
						event.action === 'taskAbort' &&
						hasName(event, CREATE_PAGE_TEMPLATE_VARIABLES_EXPERIENCE),
				),
			);
		},
	});

	experienceTracker.start({
		name: CREATE_PAGE_LOAD_DIALOG_EXPERIENCE,
		id,
		timeout: ExperienceTimeout.CREATE_DIALOG_LOAD,
	});
}

/**
 * Starts a specific compound experience and its associated sub-experience(s)
 * which are based on an editor such as TinyMCE/legacy or Fabric. Example of
 * such experiences are edit-page, add-page-comment. For such experiences we are
 * actually following a common pattern:
 * 1. Start the compound experience.
 * 2. The compound experience is set up to fail as soon as the associated editor
 *    load sub-experience fails.
 * 3. The compound experience is set up to stop (i.e. succeed, fail, or abort)
 *    as soon as the associated editor publish sub-experience stops.
 * 4. Start the associated editor load sub-experience.
 */
export function startEditorExperiences(
	compoundExperience: StartProps,
	editorLoadExperience: Pick<StartProps, 'name' | 'id' | 'timeout' | 'collect'>,
	editorPublishExperience: Pick<StartProps, 'name'>,
) {
	const experienceTracker = getExperienceTracker();

	experienceTracker.start({
		...compoundExperience,

		collect: (events, experience) => {
			experience.stopOn(
				events.find(
					({ action, name }) => action === 'taskFail' && name === editorLoadExperience.name,
				),
			);

			experience.stopOn(
				events.find(
					({ action, name }) =>
						(action === 'taskSuccess' || action === 'taskFail') &&
						name === editorPublishExperience.name,
				),
			);

			if (compoundExperience.collect) {
				compoundExperience.collect(events, experience);
			}
		},
	});

	experienceTracker.start({
		...editorLoadExperience,
		id: editorLoadExperience.id || compoundExperience.id,
		timeout: editorLoadExperience.timeout || ExperienceTimeout.EDITOR_LOAD,
		attributes: compoundExperience.attributes,
		collect: editorLoadExperience.collect,
	});
}

const stopOnCloseForEditPage = (
	events: ExperienceEvent[],
	experience: Experience,
	loadExperienceName: string,
	closeExperienceName: string,
) => {
	const loadStopExperience = events.find(
		(event) => event.name === loadExperienceName && isStop(event),
	);
	const closeStopExperience = events.find(
		(event) => event.name === closeExperienceName && isStop(event),
	);

	if (!closeStopExperience) return;

	// prettier-ignore
	if ((!loadStopExperience || loadStopExperience.action !== "taskSuccess")
		&& closeStopExperience.action === "taskSuccess") {
		// loading incomplete or failed, therefore editing session was broken and close doesn't indicate a success
		experience.abort({
			reason: "Editor closed before load completed",
			attributes: {
				loadExperienceState: loadStopExperience?.action ?? "incomplete",
			},
		});
	} else {
		experience.stopOn(closeStopExperience);
	}
};

function startEditPage(match: RouteMatch, transitionId: number, sessionData?: SessionDataType) {
	const isLegacyEditor = routeNameToEditorType(match?.name ?? '') === 'TINY';
	let editPageExperienceName = EDIT_PAGE_EXPERIENCE;
	if (match.name === EDIT_PAGE_EMBED.name) {
		editPageExperienceName = EDIT_PAGE_EMBED_EXPERIENCE;
	} else if (isLegacyEditor) {
		editPageExperienceName = EDIT_LEGACY_PAGE_EXPERIENCE;
	}

	const isCompanyHub = isCompanyHubSpaceKey(match.params.spaceKey);
	startEditorExperiences(
		/* compoundExperience */ {
			name: editPageExperienceName,
			id: match.params.contentId,
			startTime: transitionId === 0 ? 0 : undefined,
			attributes: {
				editor: routeNameToEditorType(match.name),
				contentType: routeNameToContentTypeMap[match.name],
				edition: sessionData?.edition,
				shard: sessionData?.shard,
				isCompanyHub,
				contentId: match.params.contentId,
			},
			// override with full-page-editor specific stop/collect behaviour
			collect: (events, experience) => {
				stopOnNoPermissionOrNotFound(events, experience);

				const loadExperienceName = isLegacyEditor
					? EDIT_LEGACY_PAGE_LOAD_EXPERIENCE
					: EDIT_PAGE_LOAD_EXPERIENCE;
				const closeExperienceName = isLegacyEditor
					? EDIT_LEGACY_PAGE_CLOSE_EXPERIENCE
					: EDIT_PAGE_CLOSE_EXPERIENCE;
				stopOnCloseForEditPage(events, experience, loadExperienceName, closeExperienceName);
			},
		},
		/* editorLoadExperience */ {
			name: isLegacyEditor ? EDIT_LEGACY_PAGE_LOAD_EXPERIENCE : EDIT_PAGE_LOAD_EXPERIENCE,
			collect: (events, experience) => {
				stopOnNoPermissionOrNotFound(events, experience);

				const closeExperienceName = isLegacyEditor
					? EDIT_LEGACY_PAGE_CLOSE_EXPERIENCE
					: EDIT_PAGE_CLOSE_EXPERIENCE;
				const closeExperience = events.find(
					(event) => isStop(event) && event.name === closeExperienceName,
				);
				if (closeExperience) {
					experience.abort({
						reason: 'Editor closed before load completed',
						attributes: {
							closeExperienceState: closeExperience?.action ?? 'incomplete',
						},
					});
				}
			},
		},
		/* editorPublishExperience */ {
			name: isLegacyEditor ? EDIT_LEGACY_PAGE_PUBLISH_EXPERIENCE : EDIT_PAGE_PUBLISH_EXPERIENCE,
		},
	);
}

function startViewContent(
	id: string,
	transitionId: number,
	contentType: string,
	query: MatchQuery,
	sessionData?: SessionDataType,
	attributes?: any,
) {
	// WS-3261 - We need to track if the view is coming from an external source (email notification, Slack, etc.)
	const isFromExternal = Boolean(query && query.atlOrigin);

	// Track APDEX part of the content (title and content itself)
	getExperienceTracker().start({
		name: VIEW_CONTENT_EXPERIENCE,
		id,
		startTime: transitionId === 0 ? 0 : undefined,
		timeout: ExperienceTimeout.PAGE_LOAD,
		attributes: {
			contentType,
			isFromExternal,
			edition: sessionData?.edition,
			shard: sessionData?.shard,
			...attributes,
		},
		collect: (events, experience) => {
			stopOnNoPermissionOrNotFound(events, experience);
			stopOnConnectAddon(events, experience);
		},
	});
}

function startViewPublicLinkGlobalSettings(
	tab: string | string[] | undefined,
	space: string | string[] | undefined,
	transitionId: number,
) {
	if (tab === 'public-links' && !space) {
		getExperienceTracker().start({
			name: VIEW_PUBLIC_LINK_SITE_SETTINGS_EXPERIENCE,
			id: 'public-links',
			startTime: transitionId === 0 ? 0 : undefined,
			timeout: ExperienceTimeout.PAGE_LOAD,
		});
	}
}

function startViewPublicLinksSpaceSettings() {
	getExperienceTracker().start({
		name: VIEW_PUBLIC_LINKS_SPACE_SETTINGS_EXPERIENCE,
		timeout: ExperienceTimeout.PAGE_LOAD,
	});
}

function startViewPublicLinkSharedPage(id: string, transitionId: number) {
	getExperienceTracker().start({
		name: VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE,
		id,
		startTime: transitionId === 0 ? 0 : undefined,
		timeout: ExperienceTimeout.PAGE_LOAD,
	});
}

function startTemplateEditPage(id: StartProps['id'], transitionId: number) {
	startEditorExperiences(
		/* compoundExperience */ {
			name: TEMPLATE_EDIT_PAGE_EXPERIENCE,
			id,
			startTime: transitionId === 0 ? 0 : undefined,

			collect: (events, experience) => {
				experience.stopOn(
					events.find(
						(event) => isStop(event) && event.name === TEMPLATE_EDIT_PAGE_CLOSE_EXPERIENCE,
					),
				);
			},
		},
		/* editorLoadExperience */ {
			name: TEMPLATE_EDIT_PAGE_LOAD_EXPERIENCE,
			timeout: ExperienceTimeout.TEMPLATE_EDITOR_LOAD,
		},
		/* editorPublishExperience */ {
			name: TEMPLATE_EDIT_PAGE_PUBLISH_EXPERIENCE,
		},
	);
}

function startAdvancedSearch(match: RouteMatch) {
	getExperienceTracker().start({
		name: ADVANCED_SEARCH_EXPERIENCE,
		id: objectHash(omit(match.query, ['search_id'])),
	});
}

function startArchivedPagesList() {
	getExperienceTracker().start({
		name: SPACE_ARCHIVED_PAGES_EXPERIENCE,
	});
}

function startManagePages() {
	getExperienceTracker().start({
		name: MANAGE_PAGES_EXPERIENCE,
	});
}

function startSpaceDirectory() {
	getExperienceTracker().start({
		name: SPACE_DIRECTORY_EXPERIENCE,
		timeout: ExperienceTimeout.SPACE_DIRECTORY,
	});
}

function startTemplateGallery() {
	const TemplateGallerySubExperiences = [
		TEMPLATE_GALLERY_CATEGORIES_EXPERIENCE,
		TEMPLATE_GALLERY_SPACE_SELECTOR_EXPERIENCE,
		TEMPLATE_GALLERY_CARDS_EXPERIENCE,
		TEMPLATE_GALLERY_TEMPLATE_DETAIL_EXPERIENCE,
	];

	const collectTemplateGalleryLoadExperiences = (
		events: ExperienceEvent[],
		experience: Experience,
	) => {
		collectAll(TemplateGallerySubExperiences)(events, experience);
	};

	const stopOnRestrictedPage = collectAll([RESTRICTED_PAGE_EXPERIENCE]);

	getExperienceTracker().start({
		name: TEMPLATE_GALLERY_EXPERIENCE,
		timeout: ExperienceTimeout.DEFAULT,
		collect: (events, experience) => {
			collectTemplateGalleryLoadExperiences(events, experience);
			stopOnRestrictedPage(events, experience);
		},
	});
}

function startSpacePages() {
	getExperienceTracker().start({ name: SPACE_PAGES_EXPERIENCE });
}

function startCreateContent(match: RouteMatch, sessionData?: SessionDataType) {
	const queryParams = match.query;
	const isPersonalSpace =
		queryParams.spaceKey?.[0] !== undefined
			? (queryParams.spaceKey[0] === '~').toString()
			: 'unknown';
	const flow = getSingleParam(queryParams, 'source') || 'createContentRoute';
	if (match.params.contentType === 'whiteboard') {
		getExperienceTracker().start({
			name: CREATE_WHITEBOARD_EXPERIENCE,
			timeout: ExperienceTimeout.CREATE_CONTENT,
			attributes: {
				flow,
				isPersonalSpace,
			},
			collect: (events, experience) => {
				const whiteboardExperienceStart = events.find(
					(event) => event.action === 'taskStart' && event.name === WHITEBOARD_EXPERIENCE,
				);
				if (whiteboardExperienceStart) {
					experience.succeed();
				}
			},
		});
	} else if (match.params.contentType === 'database') {
		getExperienceTracker().start({
			name: CREATE_DATABASE_EXPERIENCE,
			timeout: ExperienceTimeout.CREATE_CONTENT,
			attributes: {
				flow,
				isPersonalSpace,
			},
			collect: (events, experience) => {
				const databaseExperienceStart = events.find(
					(event) => event.action === 'taskStart' && event.name === DATABASE_EXPERIENCE,
				);
				if (databaseExperienceStart) {
					experience.succeed();
				}
			},
		});
	} else if (match.params.contentType === 'smartLinkEmbed') {
		getExperienceTracker().start({
			name: CREATE_EMBED_EXPERIENCE,
			timeout: ExperienceTimeout.CREATE_CONTENT,
			attributes: {
				flow,
				isPersonalSpace,
			},
			collect: (events, experience) => {
				const smartLinkEmbedExperienceStart = events.find(
					(event) => event.action === 'taskStart' && event.name === EMBED_EXPERIENCE,
				);
				if (smartLinkEmbedExperienceStart) {
					experience.succeed();
				}
			},
		});
	} else if (['page', 'blog'].includes(match.params.contentType)) {
		getExperienceTracker().start({
			name: CREATE_PAGE_EXPERIENCE,
			timeout: ExperienceTimeout.CREATE_CONTENT,
			attributes: {
				contentType: match.params.contentType,
				flow,
				isCreateContentRoute: true,
				isPersonalSpace,
				edition: sessionData?.edition,
				shard: sessionData?.shard,
			},
			collect: (events, experience) => {
				const editPageStarted = events.find(
					(event) =>
						event.action === 'taskStart' &&
						(event.name === EDIT_PAGE_EXPERIENCE || event.name === EDIT_LEGACY_PAGE_EXPERIENCE),
				);
				if (editPageStarted) {
					experience.succeed();
				}
			},
		});
	}
}

function startWhiteboardBoard(match: RouteMatch) {
	const whiteboardSubExperiences = [
		WHITEBOARD_CONTENT_EXPERIENCE,
		CONTENT_TYPES_HEADER_EXPERIENCE,
		VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	];

	const isWhiteboardsWatchButtonEnabled = FeatureGates.checkGate(
		'confluence_whiteboard_comments_gate',
	);

	if (isWhiteboardsWatchButtonEnabled) {
		whiteboardSubExperiences.push(CONTENT_TYPES_HEADER_WATCH_EXPERIENCE);
	}

	const contentId = match.params.contentId;

	getExperienceTracker().start({
		name: WHITEBOARD_EXPERIENCE,
		collect: (events, experience) => {
			collectAll(whiteboardSubExperiences)(events, experience);
			stopOnNoPermissionOrNotFound(events, experience);
		},
		id: contentId,
		timeout: ExperienceTimeout.DEFAULT,
	});

	whiteboardSubExperiences.forEach((name) => {
		getExperienceTracker().start({
			name,
			id: contentId,
		});
	});
}

function startDatabaseView(contentId: string) {
	const databaseSubExperiences = [
		DATABASE_CONTENT_EXPERIENCE,
		CONTENT_TYPES_HEADER_EXPERIENCE,
		VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	];

	getExperienceTracker().start({
		name: DATABASE_EXPERIENCE,
		collect: (events, experience) => {
			collectAll(databaseSubExperiences)(events, experience);
			stopOnNoPermissionOrNotFound(events, experience);
		},
		id: contentId,
		timeout: ExperienceTimeout.DEFAULT,
	});

	databaseSubExperiences.forEach((name) => {
		getExperienceTracker().start({
			name,
			id: contentId,
		});
	});
}

function startEmbedPageView(contentId: string) {
	const embedPageSubExperiences = [
		EMBED_CONTENT_EXPERIENCE,
		CONTENT_TYPES_HEADER_EXPERIENCE,
		VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	];

	getExperienceTracker().start({
		name: EMBED_EXPERIENCE,
		collect: (events, experience) => {
			collectAll(embedPageSubExperiences)(events, experience);
			stopOnNoPermissionOrNotFound(events, experience);
		},
		id: contentId,
		timeout: ExperienceTimeout.DEFAULT,
	});

	embedPageSubExperiences.forEach((name) => {
		getExperienceTracker().start({
			name,
			id: contentId,
		});
	});
}

function startViewAdminAnnouncementBannerSettings() {
	getExperienceTracker().start({
		name: ADMIN_ANNOUNCEMENT_BANNER_SETTINGS_EXPERIENCE,
		timeout: ExperienceTimeout.DEFAULT,
	});
}

function startSpaceManager() {
	getExperienceTracker().start({
		name: SPACE_MANAGER_EXPERIENCE,
		timeout: ExperienceTimeout.DEFAULT,
	});
}

function startCompanyHomeBuilderLoad() {
	getExperienceTracker().start({
		name: COMPANY_HOME_BUILDER_LOAD_EXPERIENCE,
		timeout: ExperienceTimeout.DEFAULT,
	});
}

function finishLivePageExperiences() {
	const tracker = getExperienceTracker();
	const livePageExperiencesToSucceed = [LIVE_PAGE_EDIT_EXPERIENCE, LIVE_PAGE_SESSION_EXPERIENCE];
	livePageExperiencesToSucceed.forEach((experience) => {
		const experienceState = tracker.getExperienceState(experience);
		// We want to identify if it's actively running, and if so, succeed it. This aligns with how we succeed non-live-page experiences on transition.
		// TODO MODES-4632 When migrating live-page/edit to edit-page, add an "isLivePage" attribute to edit-page experience and look for that in this conditional
		if (experienceState && !experienceState?.hasStopped) {
			tracker.succeed({ name: experience });
		}
	});
}
