import { query } from '@confluence/query-preloader-tools';
import {
	getSSRFeatureFlag,
	getSSRRenderInputs,
	ssrPrepareErrorsClient,
} from '@confluence/ssr-utilities';

import { fetchORS, getInitialState, getSmartLinks, isConfluenceURL, isJiraURL } from './index';

import { ContentSmartLinksQuery } from './ContentSmartLinksQuery.graphql';
import type {
	ContentSmartLinksQuery as ContentSmartLinksQueryType,
	ContentSmartLinksQueryVariables as ContentSmartLinksQueryVariablesType,
} from './__types__/ContentSmartLinksQuery';

declare global {
	interface Window {
		__SSR_SMARTLINKS__?: { [key: string]: any } | {};
	}
}

/*
 * preloadContentSmartLinks tracks the entire preloaded chain of queries needed to SSR Content Smart Links. This consists of two calls:
 * 1. ContentSmartLinksQuery, a GQL call to fetch SmartLinks URLs and
 * 2. A call to ORS to resolve the metadata, which uses the links returned from the previous call
 * The entirety of this chain is currently best effort, which means that SmartLinks will either resolve ALL
 * links by the time the longest preloaded query resolves or NONE.
 *
 * If a timeout is provided, Smartlinks will try and resolve as many links as possible
 * within the provided timeout.
 */
export const preloadContentSmartLinks = async (contentId: string) => {
	const { customURLs, userContext } = getSSRRenderInputs();
	// If we don't receive a URL - no reason to try and run queries
	let objectResolverURL = customURLs?.['object-resolver-service'];
	if (!objectResolverURL) {
		return;
	}

	// Call cc-graphql and get SmartLink URLs for the current page
	// begin marking blocking query time to determine remaining budget for ORS call
	const contentSmartLinksQueryStartTime = performance.now();
	const res = await query<ContentSmartLinksQueryType, ContentSmartLinksQueryVariablesType>({
		query: ContentSmartLinksQuery,
		variables: {
			contentId,
		},
	});

	// Loop through query data and pull out smart links
	const contentSmartLinksQueryDuration = performance.now() - contentSmartLinksQueryStartTime;
	const smartLinks = getSmartLinks(res);
	const confluenceSmartlinks = smartLinks.filter(isConfluenceURL);
	const jiraSmartlinks = smartLinks.filter(isJiraURL);

	const confluenceSmartlinksLength = new Set(confluenceSmartlinks.map((each) => each.resourceUrl))
		.size;
	const jiraSmartlinksLength = new Set(jiraSmartlinks.map((each) => each.resourceUrl)).size;

	// If there are no valid SmartLinks on the page - exit
	if (!smartLinks?.length) {
		return;
	}
	// Save number of SmartLinks to send as BM Event
	window['__SSR_NUMBER_OF_SMARTLINKS__'] = smartLinks.length;

	// Unlike __SSR_NUMBER_OF_SMARTLINKS__, these do not include duplicates.
	// These are used to calculate the success rate with the # of resolved smartlinks for each type, which also do not include duplicates.
	window['__SSR_NUMBER_OF_CONFLUENCE_SMARTLINKS__'] = confluenceSmartlinksLength;
	window['__SSR_NUMBER_OF_JIRA_SMARTLINKS__'] = jiraSmartlinksLength;

	// Send smart links to linking platform
	// EX: [{resourceUrl: "https://pug.jira-dev.com/wiki/people/team/3a9665f3-c92b-423a-88bd-9410c1180058"}]

	// If there is still budget remaining after ContentSmartLinksQuery to make an ORS call,
	// pass the remaining time as a param to the ORS resolver call
	const timeout = getSSRFeatureFlag('confluence.ssr.preload.query.timeout');
	if (timeout) {
		const errorBudgetForORSResolver = Math.round(timeout - contentSmartLinksQueryDuration);
		if (errorBudgetForORSResolver > 0) {
			objectResolverURL += `?timeout=${errorBudgetForORSResolver}`;
		}
	}
	try {
		const res = await fetchORS({
			url: objectResolverURL,
			body: JSON.stringify(smartLinks),
			userContext,
		});

		const __SSR_SMARTLINKS__ = await res.json();
		// Format response to fit SmartCard Provider storeOptions initial state
		const initialState = getInitialState(__SSR_SMARTLINKS__);
		// Save correctly formatted response to window obj so it can be used on SSR and SPA (no duplicate calls)
		window['__SSR_SMARTLINKS__'] = initialState;
	} catch (e) {
		ssrPrepareErrorsClient.submitError(
			new Error(`ORS returned error on resolving SmartLinks: ${e.message}`),
		);
	}
};
