/* eslint-disable check-i18n-id-prefix/i18n-id-prefix-with-package-name-FormattedMessage */
import { styled, css } from '@compiled/react';
import React, { useMemo } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ExtensionParams, Parameters } from '@atlaskit/editor-common/extensions';
import { N200, N20, R400 } from '@atlaskit/theme/colors';
import UFOInteractionIgnore from '@atlaskit/ufo-interaction-ignore';
import fg from '@atlaskit/feature-gate-js-client';
import EditorUnlinkIcon from '@atlaskit/icon/glyph/editor/unlink';

import UFOLoadHold from '@atlassian/react-ufo/load-hold';

import { borderRadius } from '@confluence/typography-placeholder';

import type { ExtensionNodeType } from '../../extensions-common';

import { MacroIcon } from './MacroIcon';
import { getExtensionBodyType } from './manifest-helpers';
import type { LegacyMacroManifest } from './extensionTypes';

const i18n = defineMessages({
	invalidAnchorMacroLabel: {
		id: 'fabric-extension-lib.anchor.placeholder.icon-label',
		defaultMessage: 'Invalid anchor link',
		description: `Label text for the icon shown in an invalid anchor macro's body`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemBody = styled.div({
	display: 'flex',
	flexDirection: 'column',
	lineHeight: 1.4,
});

const descriptionStyledSelector = 'description-styled-selector';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.span<{ 'data-styled-selector'?: string }>({
	fontSize: '11.67px',
	color: token('color.text.subtle', N200),
	marginTop: token('space.050', '4px'),
});
Description.defaultProps = {
	'data-styled-selector': descriptionStyledSelector,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Item = styled.div<{ isEditor: boolean }>({
	background: token('elevation.surface', '#fff'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) => (props.isEditor ? token('space.300', '24px') : token('space.0', '0px')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineItem = styled.div({
	background: token('color.background.accent.gray.subtlest', N20),
	display: 'inline-flex',
	alignItems: 'center',
	padding: `1px 0.24em ${token('space.025', '2px')} 0.24em`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRadius: '3px',
	fontSize: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[`[data-styled-selector="${descriptionStyledSelector}"]`]: {
			marginLeft: token('space.100', '8px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemIcon = styled.div<{ width: number; height: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props) => props.width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props) => props.height}px`,
	overflow: 'hidden',
	border: token('color.border', '1px solid rgba(223, 225, 229, 0.5)'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	boxSizing: 'border-box',
	background: token('elevation.surface', '#fff'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: (props) =>
		props.width > 32 ? token('space.200', '16px') : token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	div: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		width: `${(props) => props.width}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: `${(props) => props.height}px`,
	},
});

const anchorMacroPlaceholderStyles = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: token('color.background.neutral'),
	width: '24px',
	height: '20px',
	overflow: 'hidden',
	borderRadius: token('border.radius.100'),
	boxSizing: 'border-box',
});

export const getIconComponent = (macro: LegacyMacroManifest, testId?: string) => {
	return <MacroIcon macro={macro} testId={testId} />;
};

export const AnchorMacroPlaceholder = () => {
	const intl = useIntl();
	return (
		<div css={anchorMacroPlaceholderStyles}>
			<EditorUnlinkIcon
				label={intl.formatMessage(i18n.invalidAnchorMacroLabel)}
				primaryColor={token('color.icon.danger', R400)}
			/>
		</div>
	);
};

const InlineExtensionPlaceholder = ({
	macro,
	description,
}: {
	macro: LegacyMacroManifest;
	description?: React.ReactNode;
}) => {
	return (
		<UFOLoadHold name="inline-extension-placeholder">
			<InlineItem data-testid="inline-extension-placeholder">
				<ItemIcon width={16} height={16}>
					<MacroIcon macro={macro} size="small" />
				</ItemIcon>

				<ItemBody>
					<p>
						{macro.title}
						{description && <Description>{description}</Description>}
					</p>
				</ItemBody>
			</InlineItem>
		</UFOLoadHold>
	);
};

type macroPlaceholder = {
	title: string;
	macroName: string;
	icon: {
		width: number;
		location: string;
		height: number;
		relative: boolean;
	};
};

export const ExtensionPlaceholder = ({
	macro,
	description,
	isEditor,
}: {
	macro: LegacyMacroManifest | macroPlaceholder;
	description?: React.ReactNode;
	isEditor: boolean;
}) => {
	return (
		<UFOLoadHold name="extension-placeholder">
			<Item isEditor={isEditor} data-testid="extension-placeholder">
				<ItemIcon width={40} height={40}>
					<MacroIcon macro={macro} size="medium" />
				</ItemIcon>
				<ItemBody>
					<h4>{macro.title}</h4>
					{description && <Description>{description}</Description>}
				</ItemBody>
			</Item>
		</UFOLoadHold>
	);
};

export const editToSetupMessage = (
	<FormattedMessage
		id="confluence-fabric-editor.extension.placeholder.missing-config"
		description="Message shown when a macro is inserted but can't be rendered due to missing configuration"
		defaultMessage="Edit to setup"
	/>
);

interface PlaceholderWithAnalyticsProps {
	macro: LegacyMacroManifest;
	extension: ExtensionParams<ExtensionMacroParams>;
	isEditor: boolean;
}
const PlaceholderWithAnalytics = ({
	macro,
	extension,
	isEditor,
}: PlaceholderWithAnalyticsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useMemo(() => {
		if (createAnalyticsEvent) {
			const { extensionKey, extensionType, parameters } = extension;
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'viewed',
					actionSubject: 'macro',
					actionSubjectId: 'placeholder',
					source: isEditor ? 'editor' : 'renderer',
					attributes: {
						extensionKey,
						extensionType,
						isMissingRequiredParameters: !!parameters?.macroParams?.isMissingRequiredParameters,
					},
				},
			}).fire();
		}
		// NOTE: `extension` node changes on resize so can't use it as dependency
		// A deep check can be done using `extension.macroMetadata.macroId` though not needed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createAnalyticsEvent, macro, isEditor]);

	if (fg.checkGate('cc_page_experiences_anchor_macro_refresh') && macro.macroName === 'anchor') {
		return <AnchorMacroPlaceholder />;
	}

	if (getExtensionBodyType(macro) === 'inlineExtension') {
		return (
			<InlineExtensionPlaceholder macro={macro} description={<span>({editToSetupMessage})</span>} />
		);
	}

	return (
		<ExtensionPlaceholder isEditor={isEditor} macro={macro} description={editToSetupMessage} />
	);
};

export type ExtensionMacroParams = Parameters & {
	macroParams?: { isMissingRequiredParameters?: boolean };
};

export const getMissingConfigPlaceholderComponent = (
	macro: LegacyMacroManifest,
	extension: ExtensionParams<ExtensionMacroParams>,
	isEditor: boolean,
) => (
	<UFOInteractionIgnore>
		<PlaceholderWithAnalytics macro={macro} extension={extension} isEditor={isEditor} />
	</UFOInteractionIgnore>
);

export const getNonRenderablePlaceholderComponent = (
	macro: LegacyMacroManifest,
	type: ExtensionNodeType,
) => {
	if (type === 'inlineExtension') {
		return (
			<UFOInteractionIgnore>
				<InlineExtensionPlaceholder macro={macro} />
			</UFOInteractionIgnore>
		);
	}

	return (
		<UFOInteractionIgnore>
			<ExtensionPlaceholder
				isEditor
				macro={macro}
				description={
					<FormattedMessage
						id="confluence-fabric-editor.extension.placeholder.can-not-render-in-editor"
						description="Message shown when a macro is inserted but can't be rendered because it doesn't work in edit mode"
						defaultMessage="The results of this element aren’t visible when editing. Preview the page or publish it to see how it will look."
					/>
				}
			/>
		</UFOInteractionIgnore>
	);
};
