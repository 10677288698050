import { ConfluencePageAri } from '@atlassian/ari/confluence';

import {
	ContentUnifiedQuery,
	ContentBlogUnifiedQuery,
	ContentUnifiedWithSpaceKeyQuery,
	PageUnifiedQueryV2,
	convertToContentUnifiedQueryResult,
	canUsePageUnifiedQueryResult,
	convertToPageUnifiedQueryV2Result,
} from '@confluence/content-unified-query';
import { isNotFoundSpaceError } from '@confluence/content-unified-query/entry-points/errors';
import { query, writeQuery, isUnauthorizedError } from '@confluence/query-preloader-tools';
import type { GraphqlErrorProcessor } from '@confluence/graphql-error-processor';
import type {
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
	ContentBlogUnifiedQueryVariablesType,
	PageUnifiedQueryV2VariablesType,
	PageUnifiedQueryV2Type,
	ContentUnifiedWithSpaceKeyQueryType,
	ContentUnifiedWithSpaceKeyQueryVariablesType,
} from '@confluence/content-unified-query';

declare global {
	interface Window {
		__GRAPHQL_ERROR_PROCESSOR__?: GraphqlErrorProcessor;
	}
}

type PreloadContentParameters = {
	contentId: string;
	isBlog: boolean;
	spaceKey: string;
	shouldPreloadUsingSpaceKey?: boolean;
	featureFlags: { [key: string]: string | number | boolean };
	cloudId: string;
};

type PreloadContentResult = {
	result?: any;
	hasErrors: boolean;
};

type ChooseContentUnifiedQueryVariablesType =
	| ContentUnifiedQueryVariablesType
	| ContentBlogUnifiedQueryVariablesType
	| PageUnifiedQueryV2VariablesType;

export const handleErrors = (result: any) => {
	// NOTES:
	// 1. Preloading is "fire and forget" operation on a client as well, so we can mute
	//    some known errors, but we can't really do anything about them as they're not
	//    make it to the Apollo cache anyway and therefore don't pop at where they can
	//    be handled.
	// 2. We use implicit dependency on GraphqlErrorProcessor from "@confluence/graphql"
	//    to avoid direct import of this package into preloader bundle.
	const hasErrors = result?.errors?.length > 0;
	result?.errors?.forEach((error: any) => {
		// The "Cannot find space" condition is handled by redirecting to the correct space
		// even if error is not propagated to ContentPrerequisites where it is normally handled.
		if (isUnauthorizedError(error) || isNotFoundSpaceError(error)) {
			window?.__GRAPHQL_ERROR_PROCESSOR__?.markErrorAsHandled(error);
		}
	});

	return hasErrors;
};

export const transformContentData = (data: any) => {
	const transformedData = {
		content: data?.spaceHomepage
			? {
					nodes: [data?.spaceHomepage],
					// @ts-ignore
					__typename: 'PaginatedContentListWithChild',
				}
			: null,
		space: data?.space || null,
	};
	return transformedData;
};

export function preloadContent({
	contentId,
	spaceKey,
	isBlog,
	featureFlags,
	cloudId,
}: PreloadContentParameters): Promise<PreloadContentResult> {
	window.performance.mark('CFP-63.preloadContent.start');
	const isSSR = Boolean(process.env.REACT_SSR);
	const isMigratingContentUnifiedQuery =
		Boolean(featureFlags['confluence.frontend.content.unified.query.migration']) && isSSR;

	// Space overview
	if (spaceKey && !contentId) {
		return query<ContentUnifiedWithSpaceKeyQueryType, ContentUnifiedWithSpaceKeyQueryVariablesType>(
			{
				query: ContentUnifiedWithSpaceKeyQuery,
				variables: {
					spaceKey,
					versionOverride: null,
					isSSR,
					includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
					useNewContentTopper: Boolean(
						featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
					),
				},
				errorPolicy: 'all',
			},
		).then((result: any) => {
			const hasErrors = handleErrors(result);

			// Record the end mark and timestamp to track *both* duration of preloading
			// and timestamp of the moment when preloading finished
			window.performance.mark('CFP-63.preloadContent.end');
			window.performance.mark('CFP-63.preloadContentEnd');

			const data = result?.data;
			const homepageId = data?.spaceHomepage?.id;
			if (homepageId) {
				// update result with transformed data
				result.data = transformContentData(data);
				if (isMigratingContentUnifiedQuery) {
					// also write to PageUnifiedQueryV2 cache
					try {
						const spaceHomeAri = ConfluencePageAri.create({
							siteId: cloudId,
							pageId: data.spaceHomepage.id,
						}).toString();

						writeQuery<PageUnifiedQueryV2Type, PageUnifiedQueryV2VariablesType>({
							query: PageUnifiedQueryV2,
							variables: {
								contentId: spaceHomeAri,
								isSSR,
								includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
								useNewContentTopper: Boolean(
									featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
								),
							},
							// Since this previous condition checked for data?.spaceHomepage?.id, this return won't be undefined
							data: convertToPageUnifiedQueryV2Result(result.data, cloudId)!,
						});
					} catch (e) {
						// do nothing
					}
				}

				writeQuery<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>({
					query: ContentUnifiedQuery,
					variables: {
						contentId: homepageId,
						spaceKey,
						versionOverride: null,
						isSSR,
						includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
						useNewContentTopper: Boolean(
							featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
						),
					},
					data: { ...result.data },
				});
			}
			return { result, hasErrors };
		});
	}

	let ari: string | null = null;
	try {
		ari = ConfluencePageAri.create({
			siteId: cloudId,
			pageId: contentId,
		}).toString();
	} catch (e) {
		// do nothing
	}

	let chooseContentUnifiedQuery = ContentUnifiedQuery;
	let queryVariables: ChooseContentUnifiedQueryVariablesType = {
		contentId,
		spaceKey,
		versionOverride: null,
		isSSR,
		includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
		useNewContentTopper: Boolean(
			featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
		),
	};

	if (isBlog) {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	} else if (isMigratingContentUnifiedQuery && ari) {
		chooseContentUnifiedQuery = PageUnifiedQueryV2;
		queryVariables = {
			contentId: ari,
			isSSR,
			includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
			useNewContentTopper: Boolean(
				featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
			),
		};
	}

	return query<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>({
		query: chooseContentUnifiedQuery,
		variables: queryVariables,
		errorPolicy: 'all',
	})
		.then((res: any) => {
			if (isMigratingContentUnifiedQuery && !isBlog && ari) {
				const canUsePageQueryResult = canUsePageUnifiedQueryResult({
					pageUnifiedQueryResult: res as any,
					isMigratingContentUnifiedQuery,
					pageUnifiedQuerySkipped: false,
				});

				if (canUsePageQueryResult) {
					return {
						...res,
						data: convertToContentUnifiedQueryResult(res?.data),
					};
				} else {
					return query<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>({
						query: ContentUnifiedQuery,
						variables: {
							contentId,
							spaceKey,
							versionOverride: null,
							isSSR,
							includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
							useNewContentTopper: Boolean(
								featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
							),
						},
						errorPolicy: 'all',
					});
				}
			} else {
				// no need to to make request again for blogpost here because when choosing chooseContentUnifiedQuery,
				// the above query will always be ContentBlogUnifiedQuery for blogpost
				return res;
			}
		})
		.then((result: any) => {
			const hasErrors = handleErrors(result);

			// Record the end mark and timestamp to track *both* duration of preloading
			// and timestamp of the moment when preloading finished
			window.performance.mark('CFP-63.preloadContent.end');
			window.performance.mark('CFP-63.preloadContentEnd');

			return { result, hasErrors };
		});
}
