import { defineMessages } from 'react-intl-next';
import type { IntlShape } from 'react-intl-next';

import { copyToClipboard } from '@atlaskit/editor-common/clipboard';
import type { ContextualToolbar } from '@atlaskit/editor-common/extensions';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { VIEW_PAGE } from '@confluence/named-routes';

import type { ConfluencePageContext } from '../extensionTypes';

const i18n = defineMessages({
	anchorMacroCopyLinkCTALabel: {
		id: 'fabric-extension-lib.anchor.copy-link-button.label',
		description: 'Label for the copy link button in the anchor macro floating toolbar',
		defaultMessage: 'Copy link',
	},
	anchorMacroCopyLinkCTAToolTip: {
		id: 'fabric-extension-lib.anchor.copy-link-button.tooltip',
		description: 'Text to be shown as a tooltip for the copy link button in the anchor macro',
		defaultMessage: 'Copy a link to this anchor macro',
	},
	anchorMacroDisabledCopyLinkCTAToolTip: {
		id: 'fabric-extension-lib.anchor.copy-link-button.tooltip.disabled',
		defaultMessage: 'Edit to setup',
		description:
			'Tooltip text for when the copy link button is disabled because the anchor name is missing',
	},
});

const copyLinkToAnchor = (
	node: ADFEntity,
	pageContext: ConfluencePageContext,
	isLivePage: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	const anchorName = node.attrs!.parameters!.macroParams['']?.value;
	const { contentId, spaceKey } = pageContext;
	const pathName = isLivePage ? window.location.pathname : VIEW_PAGE.toUrl({ contentId, spaceKey });

	createAnalyticsEvent?.({
		type: 'sendUIEvent',
		data: {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'copyLinkToAnchor',
			source: 'editorFloatingToolbar',
			attributes: {
				type: node.type,
				extensionKey: node.attrs?.extensionKey,
				extensionType: node.attrs?.extensionType,
				isLivePage,
			},
		},
	}).fire();

	try {
		decodeURIComponent(anchorName);
		copyToClipboard(`${window.location.origin}${pathName}#${anchorName}`);
	} catch (_error) {}
};

const getIsValidAnchorName = (node: ADFEntity) => {
	const anchorName = node.attrs?.parameters?.macroParams['']?.value;

	if (!anchorName) return false;
	try {
		decodeURIComponent(anchorName);
		return true;
	} catch (_error) {
		return false;
	}
};

export const getAnchorMacroCopyLinkCTA = (
	intl: IntlShape,
	pageContext: ConfluencePageContext,
	isLivePage: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): ContextualToolbar[] | undefined => [
	{
		context: {
			type: 'extension',
			nodeType: 'inlineExtension',
			extensionKey: 'anchor',
			extensionType: 'com.atlassian.confluence.macro.core',
		},
		toolbarItems: (contextNode: ADFEntity, _) => {
			const isValidAnchorName = getIsValidAnchorName(contextNode);

			return [
				{
					key: 'anchor-macro-copy-link-cta',
					label: intl.formatMessage(i18n.anchorMacroCopyLinkCTALabel),
					ariaLabel: intl.formatMessage(i18n.anchorMacroCopyLinkCTALabel),
					display: 'label',
					tooltip: isValidAnchorName
						? intl.formatMessage(i18n.anchorMacroCopyLinkCTAToolTip)
						: intl.formatMessage(i18n.anchorMacroDisabledCopyLinkCTAToolTip),
					action: async () =>
						copyLinkToAnchor(contextNode, pageContext, isLivePage, createAnalyticsEvent),
					disabled: !isValidAnchorName,
				},
			];
		},
	},
];
