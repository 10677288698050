import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	PERMISSION_HELPER_ANALYTICS_SOURCE,
	LazyPermissionHelperPage,
} from '@confluence/site-settings/entry-points/PermissionHelperLoader';

export const PermissionsHelperRoute = () => <LazyPermissionHelperPage />;

PermissionsHelperRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: PERMISSION_HELPER_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
