/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useRef, Suspense } from 'react';
import { fg } from '@atlaskit/platform-feature-flags';
import { type AnalyticsWebClient } from '@atlassian/forge-ui/analytics';
import {
	ForgeErrorBoundary,
	type Extension,
	ForgeUIExtensionAnalyticsContext,
} from '@atlassian/forge-ui/ui';

import { ForgeUIExtensionPointProvider } from '@atlassian/forge-ui/provider';
import {
	type ExtensionConfiguration,
	type ProductEnvironment,
	type ForgeUIExtensionType,
} from '@atlassian/forge-ui-types';
import type {
	ExtensionParams,
	ExtensionManifest,
	Parameters as BaseParameters,
} from '@atlaskit/editor-common/extensions';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type ApolloClient from 'apollo-client';
import { token } from '@atlaskit/tokens';
import { dequal } from 'dequal/lite';
import { useIntl } from 'react-intl-next';

import { getConfig } from '../extension-provider/getForgeExtensionProvider';
import {
	LegacyUIKitTwoRenderer,
	IframeRenderer,
	ForgeUIExtension,
	UIKitOneRenderer,
	AdfExportRenderer,
} from './renderers';

import { isPdfExportEntrypoint, isAdfExportEntrypoint } from '../utils/pdfEntrypointUtils';
import type { FlagFunctions } from '../utils/getFlagProvider';

const styles2 = css({
	background: 'transparent',
	cursor: 'not-allowed',
	left: 0,
	position: 'absolute',
	height: '100%',
	top: 0,
	width: '100%',
	zIndex: 1,
});

export interface ExtensionRef {
	openConfig: () => Promise<{ [key: string]: any } | void>;
}

export type ForgeExtensionParameters = BaseParameters & {
	localId: string;
	extensionId: string;
	extension?: ForgeUIExtensionType;
	extensionTitle?: string;
	config?: ExtensionConfiguration;
	guestParams?: ExtensionConfiguration;
};

type ValueObj = {
	value: string;
};

export type ConnectToForgeParameters = Pick<ForgeExtensionParameters, 'guestParams'> & {
	macroParams?: Record<string, ValueObj>;
	macroMetadata?: {
		macroId?: ValueObj;
		schemaVersion?: ValueObj;
		title?: string;
	};
};

export type ForgeExtension = ExtensionParams<ForgeExtensionParameters>;

export type ForgeCreateExtensionProps = {
	type: string;
	attrs: {
		extensionKey: string;
		extensionType: string;
		parameters: {
			localId: string;
			extensionId: string;
			extensionTitle: string;
		};
		text: string;
	};
};

export type ForgeExtensionManifest = ExtensionManifest<ForgeExtensionParameters> & {
	connectModuleKey?: string;
	migrationKey?: string;
	createExtensionProps: () => ForgeCreateExtensionProps;
};

const APP_CONTAINER_PADDING = token('space.100', '8px');

export interface ForgeUIExtensionWrapperProps {
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	environment: ProductEnvironment;
	product: string;
	page: string;
	accountId: string;
	cloudId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	extensionData: Record<string, any>;
	dataProviders: ProviderFactory;
	extension: ForgeExtension;
	isEditing: boolean;
	flags: FlagFunctions;
	hideGlassPane?: boolean;
}
export interface ForgeExtensionProps {
	extension: ForgeExtension;
	config?: ExtensionConfiguration;
}

function useMemoedExtensionData(extensionData: Record<string, any>): Record<string, any> {
	const ref = useRef(extensionData);
	if (!dequal(ref.current, extensionData)) {
		ref.current = extensionData;
	}
	return ref.current;
}

const ForgeUIExtensionWrapper = ({
	apolloClient,
	contextIds,
	cloudId,
	environment,
	extensionData,
	accountId,
	dataProviders,
	extension,
	isEditing,
	flags,
	hideGlassPane,
}: ForgeUIExtensionWrapperProps) => {
	const {
		localId,
		extension: gqlExtension,
		extensionId,
		extensionTitle,
		// If `parameters` is missing, we'll let that bubble up to the extension's error boundary.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	} = extension.parameters!;
	const config = getConfig(extension.parameters!);

	// fallback to UIKitOneRenderer if it hits the confluence pdf export route 'wiki/pdf' AND has an forge has an export handler
	const fallbackToUIKit = isPdfExportEntrypoint(gqlExtension as Extension);

	const isAdfExport = isAdfExportEntrypoint(gqlExtension as Extension);

	const isHostedResource = Boolean(gqlExtension?.properties?.resource);
	const isUiKitTwo = gqlExtension?.properties?.render === 'native';
	const isCustomUI = isHostedResource && !isUiKitTwo;
	//inline macro doesn't support Custom UI
	const isInline = gqlExtension?.properties?.layout === 'inline' && !isCustomUI;

	const coreData = { localId, cloudId };
	const memoedExtensionData = useMemoedExtensionData({
		...extensionData,
		config,
	});
	const { locale } = useIntl();

	const renderRenderer = () => {
		if (fallbackToUIKit || !isHostedResource) {
			return (
				<UIKitOneRenderer
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					coreData={coreData}
					extension={gqlExtension as Extension}
					extensionData={memoedExtensionData}
					extensionId={extensionId}
					extensionTitle={extensionTitle}
				/>
			);
		}
		if (isAdfExport) {
			return (
				<AdfExportRenderer
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					coreData={coreData}
					dataProviders={dataProviders}
					extension={gqlExtension as Extension}
					extensionData={memoedExtensionData}
					extensionId={extensionId}
					extensionTitle={extensionTitle}
				/>
			);
		}

		// https://switcheroo.atlassian.com/ui/changes/confluence/platform.forge-ui.use-consolidated-renderer
		if (fg('platform.forge-ui.use-consolidated-renderer')) {
			return (
				<ForgeUIExtension
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					environment={environment}
					extension={gqlExtension as Extension}
					coreData={coreData}
					extensionData={memoedExtensionData}
					locale={locale}
					flags={flags}
					isInline={isInline}
				/>
			);
		}

		if (isUiKitTwo) {
			return (
				<LegacyUIKitTwoRenderer
					accountId={accountId}
					apolloClient={apolloClient}
					contextIds={contextIds}
					environment={environment}
					extension={gqlExtension as Extension}
					coreData={coreData}
					extensionData={memoedExtensionData}
					extensionTitle={extensionTitle}
					locale={locale}
					flags={flags}
					isInline={isInline}
				/>
			);
		}

		return (
			<IframeRenderer
				accountId={accountId}
				apolloClient={apolloClient}
				contextIds={contextIds}
				environment={environment}
				extension={
					// null-checked via isHostedResource
					// TODO sort out ForgeUIExtension/GQLExtension type differences
					gqlExtension as Extension
				}
				coreData={coreData}
				extensionData={memoedExtensionData}
				locale={locale}
				flags={flags}
			/>
		);
	};

	return (
		<div
			data-testid="ForgeExtensionContainer"
			// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css`
				background: ${isEditing ? token('elevation.surface', '#fff') : 'transparent'};
				padding: ${isEditing && !isInline ? `${APP_CONTAINER_PADDING}px` : 0};
				position: relative;
				display: ${isInline ? 'inline-block' : 'block'};
			`}
		>
			{renderRenderer()}
			{isEditing && !hideGlassPane && <div data-testid="GlassPane" css={styles2} />}
		</div>
	);
};

const ForgeUIExtensionWrapperWithBoundary = (props: ForgeUIExtensionWrapperProps) => {
	const { extension, product, environment, page, analyticsWebClient } = props;

	const children = (
		<ForgeErrorBoundary>
			<ForgeUIExtensionWrapper {...props} />
		</ForgeErrorBoundary>
	);

	return (
		// This Suspense fallback is for the ForgeErrorBoundary
		<Suspense fallback={null}>
			<ForgeUIExtensionPointProvider
				analyticsWebClient={analyticsWebClient}
				product={product}
				environment={environment}
				page={page}
			>
				{extension.parameters ? (
					<ForgeUIExtensionAnalyticsContext
						localId={extension.parameters.localId}
						extensionId={extension.parameters.extensionId}
					>
						{children}
					</ForgeUIExtensionAnalyticsContext>
				) : (
					children
				)}
			</ForgeUIExtensionPointProvider>
		</Suspense>
	);
};

export default ForgeUIExtensionWrapperWithBoundary;
