import { defineMessages } from 'react-intl-next';
import type { IntlShape } from 'react-intl-next';

import type { ADNode } from '@atlaskit/editor-common/validator';
import type { ExtensionParams, ExtensionManifest } from '@atlaskit/editor-common/extensions';
import fg from '@atlaskit/feature-gate-js-client';

import { fetchMacroAttributes } from '../../editor-extensions/fetchMacroAttributes';
import {
	BODIED_EXTENSION_TYPE,
	INLINE_EXTENSION_TYPE,
	MULTI_BODIED_EXTENSION_TYPE,
	REGULAR_EXTENSION_TYPE,
} from '../../extensionConstants';
import type { ExtensionNodeType } from '../../extensions-common';

import type { LegacyMacroManifest, LegacyParameter } from './extensionTypes';
import { getIconComponent } from './MacroComponents';
import { transformParams } from './field-mappings';
import { isValueSet } from './utils';

const i18n = defineMessages({
	anchroMacroTitle: {
		id: 'fabric-extension-lib.anchor.title',
		description:
			'Title text to be shown for anchor macro in the macro browser, quick insert menu, and config panel',
		defaultMessage: 'Anchor link',
	},
	anchroMacroDescription: {
		id: 'fabric-extension-lib.anchor.description',
		description:
			'Description text to be shown for anchor macro in the macro browser, quick insert menu, and config panel',
		defaultMessage: 'Create a link to anywhere on your page.',
	},
});

export const safeGetMacroName = (macro: LegacyMacroManifest) =>
	macro.alternateId || macro.macroName;

export const getExtensionBodyType = (macro: LegacyMacroManifest): ExtensionNodeType => {
	const { bodyType, outputType } = macro.formDetails.body;

	if (bodyType === 'MULTI_BODY_RICH_TEXT') {
		return MULTI_BODIED_EXTENSION_TYPE;
	}

	//        none  plain   rich
	// block   ext   ext    body
	// inline  in    in     body
	if (bodyType === 'RICH_TEXT') {
		return BODIED_EXTENSION_TYPE;
	}

	if (outputType === 'INLINE') {
		return INLINE_EXTENSION_TYPE;
	}

	return REGULAR_EXTENSION_TYPE;
};

export const buildIconObject = (macro: LegacyMacroManifest) => {
	return {
		'48': () => Promise.resolve(() => getIconComponent(macro)),
	};
};

export const getVisibleParameters = (macro: LegacyMacroManifest) =>
	(macro.formDetails &&
		(macro.formDetails.parameters || []).filter(
			(parameter: LegacyParameter) => !parameter.hidden,
		)) ||
	[];

const hasParameter = (
	paramName: string,
	extension: ExtensionParams<{ macroParams?: object }>,
	macro: LegacyMacroManifest,
) => {
	if (extension && extension.parameters && extension.parameters.macroParams) {
		const params = transformParams('transformBefore', macro, {
			...extension.parameters.macroParams,
		});

		if (paramName in params) {
			let value = params[paramName];
			if (typeof value === 'object' && value !== null && value.hasOwnProperty('value')) {
				value = value.value;
			}
			return isValueSet(value);
		}
	}
	return false;
};

// Note: macro.formDetails.parameters are coming from the backend, not from fieldTransformers under field-mappings.
export const isMissingAnyRequiredParameters = (
	macro: LegacyMacroManifest,
	extension: ExtensionParams<object>,
): boolean =>
	macro.formDetails.parameters.some(
		(param) =>
			param.required &&
			param.defaultValue === null &&
			!param.hidden &&
			!hasParameter(param.name, extension, macro),
	);

export const prepareMacro = (macroDefinition, contentId, selectedMacro?: ADNode | null) => {
	return fetchMacroAttributes({
		macroDefinition: {
			name,
			body: '',
			params: {},
			...macroDefinition,
		},
		contentId,
		selectedMacro,
	});
};

export const canRenderInTheEditor = (macro: LegacyMacroManifest): boolean => {
	switch (macro.macroName) {
		case 'pagetree': // pagetree is not spa friendly and will only work sometimes
			return false;

		default:
			return true;
	}
};

export const shouldUseMacroBrowser = (
	macro: LegacyMacroManifest,
	excluded: string[] = [],
): boolean => {
	// This is to opt-out a macro quickly from the new experience
	// using the list in `next/packages/editor-features/src/featureFlagsUtils.ts`
	if (excluded.includes(macro.macroName)) {
		return true;
	}

	// Plain text macros are not supported yet
	if (macro.formDetails.body.bodyType === 'PLAIN_TEXT') {
		return true;
	}

	return macro.customConfigEditor;
};

export const needsToForceConfig = (macro: LegacyMacroManifest): boolean => {
	// Need to force config for Plain text macros
	if (macro.formDetails.body.bodyType === 'PLAIN_TEXT') {
		return true;
	}

	switch (macro.macroName) {
		// CFE-2344: despite not having a required parameter, the children macro should open the macro browser
		// so that the user can select a parent page. Without this, on a brand new page, the children display
		// macro renders a loading state, then completely disappears and looks broken.
		case 'children': // Keep getting message saying "failed to retrieve..."
		// CONFDEV-62041: tasks report macro also doesn't have a required parameter, but without any parameters
		// it tries to search the entirety of Confluence for tasks and usually times out, so let's ask for parameters anyway.
		case 'tasks-report-macro': // Has issues.
			return true;
		default:
			return macro.customConfigEditor;
	}
};

export const shouldShowPlaceholder = (
	macro: LegacyMacroManifest,
	excludedMacros: string[] = [],
	extension: ExtensionParams<object>,
	isEditor: boolean,
	hidePlaceHolderFeatureFlag?: boolean,
) =>
	// NOTE: Since it's not possible to insert macros without required fields using
	// Macro Browser so there's no point of showing placeholder for them. @see ED-12141
	!shouldUseMacroBrowser(macro, excludedMacros) &&
	// ED-12726: Hide placeholder when FF is on and user is *not* in Editor
	(isEditor || !hidePlaceHolderFeatureFlag) &&
	// Maintaining the anchor's unique(?) status as a non-rendering-in-publish-view macro
	(isEditor || macro.macroName !== 'anchor') &&
	// Missing required parameters
	isMissingAnyRequiredParameters(macro, extension);

export const getManfiestOverridesForRefreshedAnchorMacro = (
	intl: IntlShape,
): Partial<ExtensionManifest> =>
	fg.checkGate('cc_page_experiences_anchor_macro_refresh')
		? {
				title: intl.formatMessage(i18n.anchroMacroTitle),
				description: intl.formatMessage(i18n.anchroMacroDescription),
			}
		: {};
